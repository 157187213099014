/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as queryString from 'query-string';
import { Button, Modal, Steps, Form } from 'antd';
import { Container, Row, Col } from 'reactstrap';

import { connect } from 'react-redux';
import logo from 'themes/default/logo-full.svg';
import Loading from '../../../Loading';

import { get, isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import BasicForm from 'modules/Profile/Screens/BasicForm';
import HustForm from 'modules/Profile/Screens/HustForm';

import VerifyEmailAlert from 'modules/User/Components/VerifyEmailAlert';
import moment from 'moment';
import PATHS from 'themes/paths';
import {
  TsaRegisterContextProvider,
  TsaRegisterContextService,
} from './TsaRegister/TsaRegisterContext';
import useTsaRegisterDomain from './TsaRegister/TsaRegisterDomain';
import Step1Profile from './TsaRegister/Step1Profile';
import Step2Verify from './TsaRegister/Step2Verify';
import Step3Finish from './TsaRegister/Step3Finish';

const TsaRegisterView = ({ ...props }) => {
  const [state, TsaRegisterDispatcher] = TsaRegisterContextService();
  const domain = useTsaRegisterDomain();

  const formRef = React.createRef();
  const { error, loading, currentStep, profile, billing } = state;
  const { exam, examPlan } = props;

  const search = queryString.parse(props.location.search);

  useEffect(() => {

    TsaRegisterDispatcher.updateContext({
      user: props.user,
      exam: props.exam,
    });

    const componentDidMount = () => {
      domain.getProfile(props.exam.profile_type);

      if (props.match.params.id) {
        // domain.getOneOrder(props.match.params.id);
      } else if (search.orderId) {
        const splitted = search.orderId.split('-', 3);
        domain.checkValidInvoice(splitted[1], splitted[2], search);
      } else {
        TsaRegisterDispatcher.updateContext({ loading: false });
      }
    };
    componentDidMount();
  }, []);

  if (loading === true) {
    return <Loading />;
  }

  if (error === true) {
    return <Loading error />;
  }

  if (examPlan && examPlan?.status > 0 && !search.orderId) {
    return (
      <div className="page-login app page-register-delivery">
        <Container className="">
          <Row>
            <Col xs="18" md={12} className="mt-6">
              <div className="text-center">
                <img className="logo-kt mb-4 mt-4" alt="..." src={logo} />
              </div>
              <div className="auth-form auth-form-dkdt">
                <div className="section rg-vnu-header flex text-center align-items-center">
                  <p>
                    Bạn đã đăng ký bài thi này! <br />
                    <a href="/">
                      <u>Quay lại</u>
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className="page-login app page-register-delivery">
      <Container className="">
        <Row>
          <Col xs="18" md={12} className="mt-6">
            <div className="text-center">
              <img className="logo-kt mb-4 mt-4" alt="..." src={logo} />
            </div>
            <div className="auth-form auth-form-dkdt">
              <div className="section rg-vnu-header flex text-center align-items-center">
                <h3 style={{ margin: 0 }}>Đăng ký thi</h3>
              </div>

              <Steps
                current={currentStep}
                className={`step-register section ${
                  props.isMobile ? 'step-register-mobile' : ''
                }`}
                items={[
                  {
                    title: 'Chọn hồ sơ',
                  },
                  {
                    title: 'Xác nhận thông tin',
                  },
                  {
                    title: 'Thanh toán',
                  },
                  {
                    title: 'Hoàn thành đăng ký',
                  },
                ]}
              ></Steps>
              <>
                {!props.user.email_verified && (
                  <div className="p-3">
                    <VerifyEmailAlert />
                  </div>
                )}
                {currentStep === 0 && <Step1Profile {...props} />}
                {currentStep === 1 && <Step2Verify {...props} />}
                {currentStep === 3 && <Step3Finish {...props} />}
              </>

              {currentStep < 3 && (!examPlan || examPlan?.status == 0) && (
                <div className="section">
                  <Row className="box-step-bottom">
                    <Col xs="12">
                      {!!profile && (
                        <div
                          className={`${
                            currentStep === 0 ? 'flex-end' : 'space-between'
                          } flex align-center`}
                        >
                          {currentStep !== 0 && currentStep !== 2 && (
                            <Button
                              type="ghost"
                              onClick={domain.backStep}
                              className="b-r-4"
                            >
                              Quay lại
                            </Button>
                          )}

                          {currentStep === 0 &&
                            (billing == null || billing.status != 1) &&
                            get(profile, 'billing.status') != 1 && (
                              <Button
                                type="primary"
                                className="b-r-4 btn-create-vnu btn-next-step-vnu btn-next-step-vnu-0"
                                onClick={() => domain.nextStep(formRef)}
                              >
                                Tiếp theo
                              </Button>
                            )}

                          {currentStep === 1 && (
                            <div>
                              <Button
                                type="primary"
                                loading={state.loadingStep3}
                                className="b-r-4 btn-create-vnu btn-next-step-vnu"
                                onClick={() => domain.nextStep(formRef)}
                              >
                                Đăng ký
                              </Button>
                            </div>
                          )}
                          {currentStep === 3 && (
                            <Button
                              type="primary"
                              className="b-r-4 btn-create-vnu btn-next-step-vnu"
                              onClick={() => domain.nextStep(formRef)}
                            >
                              Tiếp theo
                            </Button>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        visible={state.modal}
        onOk={domain.hideConfirm}
        onCancel={domain.hideConfirm}
        footer={null}
        keyboard
        maskClosable
        closable
        centered
        className="modal-setting-info modal-confirm-register"
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
      >
        <div className="btn-block text-center">
          <strong className="primary f-s-md mb-4">
            Thông báo chuyển trang!
          </strong>
          <p className="mt-2">
            Quá trình thanh toán lệ phí thi sẽ được thực hiện tại trang&nbsp;
            <br />
            đối tác thanh toán. Bạn vui lòng nhấn tiến hành <br />
            thanh toán để hoàn tất việc đăng ký nhé.
          </p>
          <Button
            type="primary"
            className="b-r-8 btn-step3 mt-2"
            onClick={domain.continueRegister}
          >
            Tiến hành thanh toán
          </Button>
        </div>
      </Modal>

      <Modal
        visible={state.modalSelectPayMethod}
        onOk={domain.hideModalSelectPayMethod}
        onCancel={domain.hideModalSelectPayMethod}
        footer={null}
        keyboard
        maskClosable={false}
        closable
        centered
        className="modal-setting-info modal-confirm-register"
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
      >
        <div className="btn-block text-center">
          <p className="mt-2">
            Tiếp tục thanh toán online lệ phí thi để hoàn thành quá trình đăng
            ký hồ sơ.
            <br />
            Lưu ý: Không tắt trình duyệt trong quá trình thanh toán.
          </p>

          {!!state.paymentMethodData[4] &&
            state.paymentMethodData[4].map(
              (paymentMethod, paymentMethodIndex) => (
                <Button
                  type="primary"
                  className="b-r-8 btn-step3 mt-2 mr-1"
                  onClick={() => {
                    domain.continueRegister(paymentMethod.id);
                  }}
                >
                  {paymentMethod.description}
                </Button>
              ),
            )}

          <p className="mt-4">
            Bạn cần thanh toán lệ phí thi trước{' '}
            {moment(exam.end_register_at).format('HH:mm DD/MM/YYYY')} để hoàn
            thành thủ tục đăng ký.
          </p>
        </div>
      </Modal>
      <Modal
        key={`quickView0${profile?.id}`}
        visible={state.modalDetail}
        footer={null}
        onCancel={domain.hideConfirmDetail}
        keyboard
        maskClosable
        closable
        centered
        className="modal-setting-info modal-vnu-quick-view-profile"
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
        width={1028}
      >
        {!!profile && (
          <div className="btn-block ">
            <div className="section-vnu mt-2">
              <b>{profile.name}</b>
            </div>
            <div className="section-vnu">
              {profile.code === 'BASIC' && (
                <BasicForm view profile={state.profile.data} />
              )}
              {profile.code === 'HUST' && (
                <HustForm view profile={state.profile.data} />
              )}
            </div>
            <Row>
              <Col>
                <div className="flex flex-start pb-4 step-action-vnu">
                  <Button
                    type="default"
                    size="large"
                    className="b-r-8"
                    onClick={domain.hideConfirmDetail}
                  >
                    Quay lại
                  </Button>
                </div>
              </Col>
              {(state.profile &&
                Object.keys(state.profile).length &&
                !state.profile.billing) ||
              (!isEmpty(state.profile.billing) &&
                state.profile.billing.status == 0) ? (
                <Col>
                  <div className="flex flex-end pb-4 step-action-vnu">
                    <Link
                      to={{
                        pathname: `/profile/${state.profile.code.toLowerCase()}/${
                          state.profile.id
                        }`,
                        returnUrl: `/dk/${state.exam.url}`,
                      }}
                    >
                      <Button
                        size="large"
                        className="b-r-8 color-light btn-ths"
                      >
                        Sửa hồ sơ
                      </Button>
                    </Link>

                    <Button
                      type="primary"
                      size="large"
                      className="ml-2 b-r-8"
                      onClick={() => domain.nextStep(formRef)}
                    >
                      Tiếp tục
                    </Button>
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </div>
        )}
      </Modal>
    </div>
  );
};

const WrapComponent = ({ ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 }, undefined);
  const [form] = Form.useForm();

  return (
    <TsaRegisterContextProvider {...props}>
      <TsaRegisterView form={form} isMobile={isMobile} {...props} />
    </TsaRegisterContextProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(WrapComponent);
