import React, { useEffect, useState } from 'react';
import {
  Space,
  Tag,
  Row,
  Button,
  Typography,
  Modal,
  Col,
  message,
  Form,
  Input,
} from 'antd';
import classNames from 'classnames';
import styles from './RegisterExamCard.module.less';
import { useHistory } from 'react-router';
import moment from 'moment';
import { formatDate, formatVndMoney } from 'app/utils/functions';
import { get } from 'lodash';
import ExamTypes from './ExamTypes';
import PATHS from 'themes/paths';
import { Link } from 'react-router-dom';
import ExamPlanDetail from '../Screens/ExamPlanDetail';
import { checkCode, getResultExamTest } from '../service';
const { Text } = Typography;

const RegisterExamCard = ({
  isGuest,
  showActions = false,
  emailVerified = false,
  testingToken = '',
  exam: {
    id,
    name,
    start_register_at,
    end_register_at,
    amount,
    start_at,
    end_at,
    url,
    is_paid,
    type,
    test_urls,
    detail_url,
    configs,
    register_status,
    exam_plan,
    publish_score,
  },
}) => {
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalTestUrl, setShowModalTestUrl] = useState(false);
  const [showModalTestExam, setShowModalTestExam] = useState(false);
  const [examPlanIdSelected, setExamPlanIdSelected] = useState(false);
  const [showModalResultTest, setShowModalResultTest] = useState(false);
  const [showFormTestCode, setShowFormTestCode] = useState(false);
  const [disabledSubmitTextExam, setDisabledSubmitTextExam] = useState(false);
  const [getTimeSubmit, setTimeSubmit] = useState(localStorage.getItem('time_submit') || null)
  const [dataTest, setDataTest] = useState([
    {
      overall_score: '-',
      Toán: '-',
      'Khoa học': '-',
      'Đọc hiểu': '-',
    },
    {
      overall_score: '-',
      Toán: '-',
      'Khoa học': '-',
      'Đọc hiểu': '-',
    },
  ]);
  const history = useHistory();
  const formatDayOfWeek = (d) => {
    if (d == 1) {
      return 'Thứ hai,';
    } else if (d == 2) {
      return 'Thứ ba,';
    } else if (d == 3) {
      return 'Thứ tư,';
    } else if (d == 4) {
      return 'Thứ năm,';
    } else if (d == 5) {
      return 'Thứ sáu,';
    } else if (d == 6) {
      return 'Thứ bảy,';
    } else if (d == 7) {
      return 'Chủ nhật,';
    }
  };
  const renderFee = (type = 1, amount = 0 ) => {
    if(type == 3) {
      return 'Mã thi thử'
    }

    return amount == 0 ? 'Miễn phí' : formatVndMoney(amount)
  };
  const getResults = async () => {
    const response = await getResultExamTest(id);
    if (response?.code != 200) {
      return false;
    }
    let arrayResults = [];
    const { data } = response;
    if (data?.testCode.length < 1) {
      return;
    }
    data?.testCode.map((e) => {
      return arrayResults.push(e.data);
    });
    if (arrayResults.length < 2) {
      arrayResults.push({
        overall_score: '-',
        Toán: '-',
        'Khoa học': '-',
        'Đọc hiểu': '-',
      });
    }

    setDataTest(arrayResults);
  };

  useEffect(()=>{
    if (!getTimeSubmit) {
      return
    }
    
    const interval = setInterval(() => {
      const timestampNow = Math.floor(Date.now() / 1000);
      if(Number(timestampNow) - Number(getTimeSubmit) < 300) {
        setDisabledSubmitTextExam(true)

        return form.setFields([
          {
            name: 'code',
            errors: ['Vui lòng chờ trong 5 phút'],
          },
        ]);
      } else{
        setDisabledSubmitTextExam(false)
        setTimeSubmit(null)
        clearInterval(interval)
        localStorage.removeItem('time_submit');

        return form.setFields([
          {
            name: 'code',
            errors: [],
          },
        ]);
      }
    }, 1000);
    
    return () => clearInterval(interval);

  },[getTimeSubmit])

  const onFinish = async (values) => {
    if(disabledSubmitTextExam) {
      return;
    }
    const timestampNow = Math.floor(Date.now() / 1000);
    localStorage.setItem('time_submit', timestampNow);
    setTimeSubmit(timestampNow)

    values.exam_id = id;
    const response = await checkCode(values);
    if (response.code != 200) {
      return form.setFields([
        {
          name: 'code',
          errors: [response.message],
        },
      ]);
    }
    window.location.assign(
      process.env.REACT_APP_LINK_EXAM_TEST +
        '/?code=' +
        values.code.toUpperCase(),
    );
  };

  return (
    <div className={styles.container} style={configs?.style || null}>
      <div className={styles.name}>{name}</div>
      <div className={styles.body}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row align="middle" justify="space-between">
            <div className={styles.childLeft}>Hình thức thi:&nbsp;</div>
            <div className={styles.childRight}>
              <Text>
                <Tag
                  color={
                    type == ExamTypes.ONLINE || type == ExamTypes.ONLINE_2
                      ? 'success'
                      : 'error'
                  }
                  style={{ margin: 0 }}
                >
                  {type == ExamTypes.ONLINE && 'Thi trực tuyến'}
                  {type == ExamTypes.ONLINE_2 && 'Thi trực tuyến'}
                  {type == ExamTypes.ONLINE_3 && 'Thi trực tuyến'}
                  {type == ExamTypes.OFFLINE && 'Thi tại địa điểm thi'}
                </Tag>
              </Text>
            </div>
          </Row>
          <Row align="middle" justify="space-between">
            <div className={styles.childLeft}>Thời gian đăng ký:&nbsp;</div>
            <div className={styles.childRight}>
              {moment(start_register_at).format('DD/MM/YYYY')}&nbsp;-&nbsp;
              {formatDate(end_register_at)}
            </div>
          </Row>
          <Row align="middle" justify="space-between">
            <div className={styles.childLeft}>Lệ phí:&nbsp;</div>
            <div className={styles.childRight}>
              <Text strong>
                {renderFee(type, amount)}
                {/* {amount == 0 ? 'Miễn phí' : formatVndMoney(amount)} */}
              </Text>
            </div>
          </Row>
          <Row align="middle" justify="space-between">
            <div className={styles.childLeft}>Thời gian thi:&nbsp;</div>
            <div className={styles.childRight}>
              {start_at == end_at ? (
                <>
                  {formatDayOfWeek(moment(start_at).isoWeekday())}{' '}
                  {formatDate(start_at)}
                </>
              ) : (
                <>
                  {formatDate(start_at)}&nbsp;-&nbsp;
                  {formatDate(end_at)}
                </>
              )}
            </div>
          </Row>
        </Space>
      </div>
      {showActions && (
        <>
          <Row
            align="middle"
            justify="space-between"
            className={styles.actions}
          >
            {/* left */}
            {type == ExamTypes.OFFLINE && register_status == 'paid' ? (
              <Button
                type="link"
                className={classNames([styles.btnDetail, 'u-link', 'pl-0'])}
                href={null}
                onClick={() => {
                  setExamPlanIdSelected(true);
                }}
              >
                {!!publish_score
                  ? 'Xem kết quả'
                  : 'Thông tin đăng ký & lịch thi'}
              </Button>
            ) : type == ExamTypes.ONLINE_3 && register_status == 'paid' ? (
              <Button
                type="link"
                className={classNames([styles.btnDetail, 'u-link', 'pl-0'])}
                href={null}
                onClick={() => {
                  getResults();
                  setShowModalResultTest(true);
                }}
              >
                Xem kết quả
              </Button>
            ) : (
              <span></span>
            )}

            {/* right */}
            {register_status != 'ended' && type == ExamTypes.ONLINE && (
              <>
                {test_urls.length == 0 && (
                  <Button type="primary">Đang chờ</Button>
                )}
                {test_urls.length == 1 && (
                  <Button type="primary">
                    <a href={test_urls[0].url} target="_blank" rel="noreferrer">
                      Thi ngay
                    </a>
                  </Button>
                )}
                {test_urls.length > 1 && (
                  <Button
                    type="primary"
                    onClick={() => setShowModalTestUrl(true)}
                  >
                    Thi ngay
                  </Button>
                )}
              </>
            )}

            {register_status == 'ended' ? (
              <Button type="primary" disabled>
                Hết hạn đăng ký
              </Button>
            ) : (
              <>
                {(type == ExamTypes.OFFLINE ||
                  type == ExamTypes.ONLINE_2 ||
                  type == ExamTypes.ONLINE_3) && (
                  <>
                    {isGuest && (
                      <Button
                        type="primary"
                        disabled={moment(start_register_at) > moment()}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        {moment(start_register_at) > moment()
                          ? 'Sắp diễn ra'
                          : 'Đăng ký'}
                      </Button>
                    )}
                    {!isGuest && (
                      <>
                        {register_status == 'paid' &&
                          type == ExamTypes.ONLINE_2 && (
                            <Button
                              type="success"
                              onClick={() => setShowModalTestUrl(true)}
                            >
                              Vào thi ngay
                            </Button>
                          )}
                        {register_status == 'paid' &&
                          type == ExamTypes.ONLINE_3 && (
                            <Button
                              type="success"
                              onClick={() => setShowModalTestExam(true)}
                            >
                              Vào thi ngay
                            </Button>
                          )}
                        {register_status == 'paid' &&
                          type == ExamTypes.OFFLINE && (
                            <Button
                              type="success"
                              onClick={() => {
                                setExamPlanIdSelected(true);
                              }}
                            >
                              Đã đăng ký
                            </Button>
                          )}
                        {(register_status == 'none' ||
                          register_status == 'pending') && (
                          <Button
                            type={
                              register_status == 'none' ? 'primary' : 'warning'
                            }
                            disabled={moment(start_register_at) > moment()}
                            onClick={() => {
                              if (!emailVerified) {
                                message.error(
                                  'Bạn cần thực hiện xác thực email trước khi đăng ký',
                                );

                                return;
                              }
                              history.push(`/dk/${url}`);
                            }}
                          >
                            {moment(start_register_at) > moment()
                              ? 'Sắp diễn ra'
                              : register_status == 'none'
                              ? 'Đăng ký'
                              : 'Tiếp tục đăng ký'}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
          {examPlanIdSelected && (
            <ExamPlanDetail
              examPlanId={exam_plan?.id}
              examPlanIdSelected={examPlanIdSelected}
              setExamPlanIdSelected={setExamPlanIdSelected}
            />
          )}
        </>
      )}
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        keyboard
        maskClosable={false}
        closable
        centered
        className="modal-setting-info modal-confirm-register"
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
      >
        <div className="btn-block text-center">
          <p>
            <strong>Vui lòng đăng nhập để tiếp tục </strong>
          </p>
          <Row>
            <Col xs={24} md={12}>
              <Button
                type="link"
                className="b-r-8 btn-step3 mt-2"
                onClick={() => {
                  history.push(`${PATHS.register}?returnUrl=/dk/${url}`);
                }}
              >
                Chưa có tài khoản?
              </Button>
            </Col>
            <Col xs={24} md={12}>
              <Button
                type="primary"
                className="b-r-8 btn-step3 mt-2"
                onClick={() => {
                  history.push(`${PATHS.login}?returnUrl=/dk/${url}`);
                }}
              >
                Đăng nhập
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        open={showModalDetail}
        onCancel={() => setShowModalDetail(false)}
        footer={null}
        keyboard
        maskClosable={false}
        closable
        centered
        width={640}
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
      >
        <h3>{name}</h3>
        <h4>Nội dung chi tiết sẽ được cập nhật sau</h4>
      </Modal>
      <Modal
        open={showModalTestUrl}
        onCancel={() => setShowModalTestUrl(false)}
        footer={null}
        keyboard
        maskClosable={false}
        closable
        centered
        width={640}
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
      >
        <h3>Chọn phần thi</h3>
        <Space direction="vertical" style={{ width: '100%' }}>
          {typeof test_urls == 'object' &&
            test_urls.map(({ subject, url }, index) => {
              return (
                <Button key={index} type="primary" block>
                  <a
                    href={url + '/' + testingToken}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {subject}
                  </a>
                </Button>
              );
            })}
        </Space>
      </Modal>
      <Modal
        open={showModalTestExam}
        onCancel={() => setShowModalTestExam(false)}
        footer={null}
        keyboard
        maskClosable={false}
        closable
        centered
        width={800}
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
        title={name}
      >
        <Row className={styles.modal_test_exam}>
          <Col md={10}>
            <div className={styles.modal_test_exam_left}>
              <h5>Bạn đã có mã thi thử chưa?</h5>
              <Button type="none" block>
                <a
                  href={process.env.REACT_APP_LINK_TOTURIAL_EXAM_TEST}
                  target="_blank"
                  rel="noreferrer"
                >
                  Mã thi thử là gì? Tôi chưa có mã thi thử
                </a>
              </Button>
              {showFormTestCode ? (
                <Form
                  form={form}
                  onFinish={onFinish}
                  className={styles.form_test_code}
                  autoComplete="off"
                >
                  <Form.Item
                    name="code"
                    className={styles.form_test_code}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập mã code',
                      },
                      // {
                      //   max: 6,
                      //   message: 'Mã code có 6 ký tự',
                      // },
                      // {
                      //   min: 6,
                      //   message: 'Mã code có 6 ký tự',
                      // },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          const regex = /^A.{5}$/;
                          if(!regex.test(value)) {
                            return Promise.reject(new Error('Mã dự thi không đúng'));
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ marginBottom: 0 }}
                      placeholder="Nhập mã code"
                    />
                  </Form.Item>
                  <Button disabled={disabledSubmitTextExam} type="primary" block htmlType="submit">
                    Tiếp tục vào thi
                  </Button>
                </Form>
              ) : (
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    setShowFormTestCode(true);
                  }}
                >
                  Tôi đã có mã, tiếp tục vào thi
                </Button>
              )}
            </div>
          </Col>
          <Col md={13} offset={1} className={styles.modal_test_exam_right}>
            <h5>Mã thi thử là gì? và làm cách nào có Mã thi thử?</h5>
            <p>
              Bài thi thử TSA là một trải nghiệm mới, được cung cấp kèm theo
              cuốn "Cẩm nang thi Đánh giá tư duy" do Đại học Bách khoa Hà Nội
              phát hành.
            </p>
            <p>
              Cuốn "Cẩm nang thi Đánh giá tư duy" sẽ giúp cho các thí sinh hiểu
              rõ hơn về cấu trúc và nội dung của bài thi, làm quen với các dạng
              câu hỏi, hướng dẫn ôn tập, phương pháp làm bài, phân tích ví dụ
              minh họa. Bên cạnh đó, trong mỗi phần thi cũng trình bày tóm lược
              kiến thức nền tảng liên quan, giới thiệu đề thi minh họa có phân
              tích hướng dẫn giải, hướng dẫn ôn tập theo chủ đề và một số đề ôn
              luyện thêm kèm đáp án.
            </p>

            <p>
              Cuối cùng là nội dung hướng dẫn sử dụng phần mềm thi, đặc biệt đi
              kèm sách là 02 mã dự thi để thí sinh có thể trải nghiệm 02 bài thi
              thử trực tiếp trên hệ thống. Kết quả của 02 bài thi thử sẽ là cơ
              sở quan trọng để thí sinh tự đánh giá được năng lực tư duy hiện
              tại của bản thân, từ đó có được kế hoạch học tập và lộ trình tham
              gia kỳ thi một cách phù hợp nhất.
            </p>
            <p>
              Nếu bạn chưa có trong tay cuốn Cẩm nang này,
              <br />
              cùng{' '}
              <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_LINK_SUGGUEST_EXAM_TEST}>
                Tìm hiểu thêm
              </a>{' '}
              nhé.
            </p>
            <p>Nếu bạn đã có cẩm nang, hãy bắt đầu thi thử ngay!</p>
            <p className="text-right">
              <a href="https://nxbbachkhoa.vn/ebook/12334" target="_blank" rel="noreferrer">
                Đọc phiên bản eBook miễn phí.
              </a>
            </p>
          </Col>
        </Row>
      </Modal>
      <Modal
        open={showModalResultTest}
        onCancel={() => setShowModalResultTest(false)}
        footer={null}
        keyboard
        maskClosable={false}
        closable
        centered
        width={800}
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
        title={name}
      >
        <Row className={styles.modal_test_exam} gutter={[30, 30]}>
          {dataTest.map((e, index) => {
            return (
              <Col md={12} key={'test_exam' + index}>
                <h5>Lần thi {index + 1}</h5>
                <div className={styles.modal_test_exam_box_result}>
                  <Row>
                    <Col
                      span={8}
                      className={styles.modal_test_exam_box_result_left}
                    >
                      <div>
                        <h4>Điểm TSA</h4>
                        <h3>{e.overall_score}</h3>
                      </div>
                    </Col>
                    <Col
                      span={16}
                      className={styles.modal_test_exam_box_result_right}
                    >
                      <p>
                        <b>Số câu đúng:</b>
                      </p>
                      <p>
                        Tư duy Toán học: <span>{e['Toán']}</span>
                      </p>
                      <p>
                        Tư duy Đọc hiểu: <span>{e['Đọc hiểu']}</span>
                      </p>
                      <p>
                        Tư duy Khoa học/Giải quyết vấn đề:{' '}
                        <span>{e['Khoa học']}</span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })}
        </Row>
      </Modal>
    </div>
  );
};

export default RegisterExamCard;
