/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  Radio,
  Space,
  Button,
  Badge,
  Card,
  message,
  Popover,
  Typography,
  Modal,
  Skeleton,
  Progress,
  Row,
  Col,
  Popconfirm,
} from 'antd';

import { formatVndMoney } from '../../../../app/utils/functions';
import profileAva from '../../../../assets/img/theme/hust.png';

import moment from 'moment';
import { TsaRegisterContextService } from '../TsaRegister/TsaRegisterContext';
import useTsaRegisterDomain from '../TsaRegister/TsaRegisterDomain';

const Step2Verify = ({ ...props }) => {
  const [state, TsaRegisterDispatcher] = TsaRegisterContextService();
  const domain = useTsaRegisterDomain();
  const [testSites, setTestSites] = useState([]);

  const formRef = React.createRef();
  const { profile } = state;
  const { exam } = props;

  useEffect(() => {
    const componentDidMount = async () => {
      let testSites = await domain.getTestSites();
      console.log('testSites', testSites);
      setTestSites(testSites);
    };

    componentDidMount();
  }, []);

  const selectTestSite = (id) => {
    TsaRegisterDispatcher.updateContext({ testSiteIdSelected: id });
  };

  return (
    <>
      <div className="section section-header-step-2">
        <div className="text-center" style={{ padding: '12px 0 20px 0' }}>
          <h2>Xác nhận đăng ký {exam.name}</h2>
        </div>

        {!!testSites && testSites.length > 0 && (
          <>
            <h6 className="mb-4">
              <b>Chọn địa điểm thi</b>
            </h6>
            <div className="body-step-2">
              {testSites.map((testSite, testSiteIndex) => {
                let color = '#00ab1d';
                let percent = Math.round(
                  (testSite.count_test_taker * 100) / testSite.max_test_taker,
                );

                if (percent > 90) {
                  color = '#f30000';
                } else if (percent > 80) {
                  color = '#f33800';
                  percent = percent + 9;
                } else if (percent > 60) {
                  percent = percent + 15;
                  color = '#f37b00';
                } else if (percent > 40) {
                  percent = percent + 20;
                  color = '#f3a800';
                } else if (percent > 20) {
                  color = '#9ccf00';
                  percent = percent + 20;
                } else if (percent > 1) {
                  percent = percent + 10;
                }

                return (
                  <Row key={testSiteIndex}>
                    <Col span={16} className="d-flex">
                      <Radio
                        className="font-14"
                        checked={state.testSiteIdSelected == testSite.id}
                        onClick={() => selectTestSite(testSite.id)}
                      >
                        <b style={{ color: '#333' }}>{testSite.name}</b>
                        <br />
                        <small>{testSite.address}</small>
                      </Radio>
                    </Col>
                    <Col span={8}>
                      <span className="ml-2 float-right">
                        <span className="mr-2">
                          {testSite.max_test_taker <=
                          testSite.count_test_taker ? (
                            'Đã hết chỗ'
                          ) : (
                            <>
                              {/*  Còn{' '}
                              {testSite.max_test_taker -
                                testSite.count_test_taker >
                              0
                                ? testSite.max_test_taker -
                                  testSite.count_test_taker
                                : 0}{' '}
                              chỗ */}
                            </>
                          )}
                        </span>
                      </span>

                      <Progress
                        trailColor="#ccc"
                        strokeColor={color}
                        className=" mb-3"
                        percent={percent}
                        showInfo={false}
                      />
                    </Col>
                    <hr />
                  </Row>
                );
              })}
            </div>
          </>
        )}
        <h6 className="mb-2 mt-4">
          <b>Thông tin dự thi và lệ phí</b>
        </h6>
        <p className="m-0 p-0">
          Thí sinh kiểm tra kỹ thông tin cá nhân dưới đây
        </p>
        <Row className="mb-4 mt-2">
          <Col md={12} sm={24}>
            <div className="profile-selected flex cursor align-items-center temp-item">
              <img
                className="profile-ava"
                src={profile?.data?.photo ? profile?.data?.photo : profileAva}
              />
              <div className="title-profile">
                <Space direction="vertical" size={1}>
                  <b>
                    [{profile.code}] - {profile.name}
                  </b>
                  <span>{profile.data.name}</span>
                  <span>Email: {profile.data.email}</span>
                  <span>
                    CCCD/CMND:&nbsp;
                    {profile.data.cmnd}
                  </span>
                  <span>
                    Ngày sinh:&nbsp;
                    {moment(profile.data.birthday, 'MM-DD-YYYY').format(
                      'DD/MM/YYYY',
                    )}
                  </span>
                </Space>
              </div>
            </div>
          </Col>
          <Col md={12} sm={24} className="text-right">
            <div className="align-center lephi">
              <span>Lệ phí thi</span>
              <br />
              <b className="ml-1 f-s-l danger">
                {formatVndMoney(state.exam.amount)}
              </b>
            </div>
          </Col>
        </Row>
      </div>

      <div className="body-step-2">
        <div className="mb-2">
          <h6 className="mb-2">
            <b>Nội dung quy chế, quy định dự thi và xét công nhận kết quả</b>
          </h6>
        </div>
        <Row>
          <Col>
            <div
              style={{
                display: 'block',
                maxHeight: 200,
                minHeight: 120,
                overflowX: 'auto',
              }}
              className="form-control b-r-8 content-rule-step2"
              dangerouslySetInnerHTML={{
                __html:
                  exam.terms ||
                  `<a>- Nội dung quy chế, quy định dự thi và xét công nhận kết quả dựa trên nội dung quy chế thi THPT Quốc gia và theo quy định chung của trường.
                  <br />- Trường có thẩm quyền và quyết định cao nhất để giải quyết các tranh chấp về điểm, khiếu nại, xử lý vi phạm trong suốt quá trình thi.`,
              }}
            ></div>

            <div className="mt-2 checkbox-step2 b-r-4">
              <Checkbox
                className="font-14"
                //disabled={state.disabled}
                onChange={domain.onChangeCheckbox}
              >
                Tôi xác nhận đã đọc, hiểu rõ và chấp nhận các điều khoản.
              </Checkbox>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Step2Verify;
