import React, { useEffect } from 'react';
import { Row, Button, Col, Form, Input, message } from 'antd';
import { saveCashbackInfo } from '../service';
import { getErrorMessages } from 'app/utils/functions';
const RegisterCashback = ({ examPlan, onCancel, onSuccess }) => {
  useEffect(() => {}, []);

  const onFinish = async (_value) => {
    let response = await saveCashbackInfo({
      exam_plan_id: examPlan.id,
      ..._value,
    });

    if (response.data?.code == 200) {
      message.success('Cập nhật thông tin thành công');
      onSuccess();
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  return (
    <div className="pr-3">
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={examPlan.cashback_info}
      >
        <p className="text-center mt-3 ">
          <b>Hủy tham dự thi</b>
          <br />
          <small>
            Vui lòng nhập thông tin ngân hàng để nhận hoàn trả lệ phí thi.
          </small>
        </p>

        <Row>
          <Col xs={24} className="">
            <Form.Item
              label={<span>Tên ngân hàng</span>}
              name="bank_name"
              rules={[
                { required: true, message: 'Vui lòng nhập Tên ngân hàng' },
              ]}
              className="mb-2"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<span>Họ tên chủ tài khoản</span>}
              name="full_name"
              rules={[{ required: true, message: 'Vui lòng nhập Họ tên' }]}
              className="mb-2"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<span>Số tài khoản</span>}
              name="bank_account_number"
              rules={[
                { required: true, message: 'Vui lòng nhập Số tài khoản' },
              ]}
              className="mb-2"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<span>Chi nhánh</span>}
              name="bank_branch"
              className="mb-2"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="mb-1">
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: '5px' }}
            block
          >
            Xác nhận
          </Button>
        </Form.Item>
      </Form>
      <p className="text-center m-0 p-0">
        <a onClick={onCancel}>
          <small>Quay lại</small>
        </a>
      </p>
    </div>
  );
};
export default RegisterCashback;
