import React, { useState, useEffect } from 'react';
import {
  EditOutlined,
  EnvironmentOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Space,
  Tag,
  Row,
  Button,
  Typography,
  Modal,
  Col,
  message,
  Skeleton,
  Card,
  Tooltip,
  Radio,
  Progress,
  Popconfirm,
  Form,
  Input,
} from 'antd';

import moment from 'moment';
import {
  formatDate,
  formatVndMoney,
  getErrorMessages,
} from 'app/utils/functions';
import {
  getExamPlanDetail,
  getTestSites,
  changeTestSite,
} from 'modules/Register/service';
import ExamTypes from '../Components/ExamTypes';
import RegisterReciveCer from '../Components/RegisterReviceCer';

import { useParams } from 'react-router-dom';
import RegisterCashback from '../Components/RegisterCashback';

const { Text } = Typography;

const ExamPlanDetail = (props) => {
  const { examPlanId, setExamPlanIdSelected, examPlanIdSelected } = props;
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState({});
  const [examPlan, setExamPlan] = useState({});
  // const [billing, setBilling] = useState({});
  const [testSite, setTestSite] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listTestSite, setListTestSite] = useState([]);
  const [isRegisterReciveCer, setRegisterReciveCer] = useState(false);
  const [isOpenFormCashback, setIsOpenFormCashback] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    getDataListTestSites();
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    console.log('examPlanId', examPlanId);
    if (!examPlanId) {
      setRegisterReciveCer(false);
    }
  }, [props]);
  useEffect(() => {
    if (isRegisterReciveCer) {
      return;
    }
    console.log('isRegisterReciveCer', isRegisterReciveCer);
    getData();
  }, []);

  const getData = async () => {
    let response = await getExamPlanDetail(examPlanId);

    setLoading(false);

    if (response.data?.code == 200) {
      setExam(response.data.data.exam);
      setExamPlan(response.data.data.examPlan);
      //  setBilling(response.data.data.billing);
      setTestSite(response.data.data.testSite);
    }
  };
  const getDataListTestSites = async () => {
    let response = await getTestSites(examPlan.exam_id);
    // console.log('res', response.data.data.test_sites);
    setListTestSite(response.data.data.test_sites);
  };
  const handleChangeTestSite = async (testSiteId) => {
    let response = await changeTestSite(examPlan.id, testSiteId);
    if (response.data?.code == 200) {
      getData();
      setIsModalOpen(false);
      message.success('Bạn đã chọn địa điểm thi thành công');
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  const openFormReceiveCer = () => {
    if (moment(exam?.expired_time_receive_cer).unix() < moment().unix()) {
      let message_error = 'Đã hết hạn thời gian đăng ký';
      if (examPlan?.info_receive_cer) {
        message_error = 'Đã hết hạn thời gian thay đổi thông tin';
      }
      message.error(message_error);
      return;
    }
    setRegisterReciveCer(true);
  };

  return (
    <Modal
      open={examPlanIdSelected}
      onOk={() => {
        setExamPlanIdSelected(false);
        setRegisterReciveCer(false);
      }}
      onCancel={() => {
        setExamPlanIdSelected(false);
        setRegisterReciveCer(false);
      }}
      footer={null}
      closable
      centered
      width={800}
      //className="modal-setting-info modal-confirm-register"
      closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <div className="social__header">
            <div className="social__title">
              <span className="title-classes title-classes-light">
                {exam.name}
              </span>
            </div>
            <hr className="line mt-3" />
          </div>

          <Row>
            <Col xs={24} md={12} className="mb-3">
              {!examPlan?.overall_score && (
                <>
                  <p>
                    <b>Thông tin đăng ký</b>
                  </p>
                  {/*  <p>
                    Lệ phí thi: {formatVndMoney(billing.invoice_total_amount)}
                  </p> */}
                  <p>
                    Trạng thái thanh toán:{' '}
                    <Text>
                      <Tag color={'success'} style={{ margin: 0 }}>
                        Đã thanh toán
                      </Tag>
                    </Text>
                  </p>
                  {examPlan.test_site_id > 0 && (
                    <>
                      <p className="mb-1">
                        <span>Địa điểm:</span> {testSite?.name}
                        {!!testSite?.address && <>, {testSite?.address}</>}
                      </p>
                      <p className="m-0">
                        {exam.end_register_at >
                          moment().format('YYYY-MM-DD HH:mm:ss') && (
                          <Button
                            type="primary"
                            size="small"
                            onClick={showModal}
                            key="1"
                          >
                            <small>Chọn/Đổi địa điểm thi</small>
                          </Button>
                        )}
                      </p>
                    </>
                  )}

                  {examPlan.test_site_id > 0 &&
                    exam?.allow_cashback == 1 &&
                    !isOpenFormCashback && (
                      <p className="m-0">
                        {exam.end_register_at >
                          moment().format('YYYY-MM-DD HH:mm:ss') && (
                          <a
                            className="ant-btn ant-btn-link u-link pl-0 mt-2"
                            onClick={() => {
                              setIsOpenFormCashback(true);
                            }}
                            rel="noreferrer"
                          >
                            Hủy tham dự thi - Nhận hoàn tiền
                          </a>
                        )}
                      </p>
                    )}
                  {examPlan.test_site_id == 0 &&
                    !!examPlan.cashback_info &&
                    !isOpenFormCashback && (
                      <>
                        <p className="mb-0">
                          <b>
                            Bạn đã xác nhận không dự thi và nhận hoàn trả lệ
                            phí.
                          </b>
                          <br />
                          Thông tin ngân hàng: <br />
                          Ngân hàng: <i>
                            {examPlan.cashback_info?.bank_name}
                          </i>{' '}
                          <br />
                          Chủ tài khoản:{' '}
                          <i>{examPlan.cashback_info?.full_name}</i> <br />
                          Số tài khoản:{' '}
                          <i>{examPlan.cashback_info?.bank_account_number}</i>
                          <br />
                          Chi nhánh:{' '}
                          <i>{examPlan.cashback_info?.bank_branch || '--'}</i>
                          <br />
                          <a
                            className="ant-btn ant-btn-link u-link pl-0  "
                            onClick={() => {
                              setIsOpenFormCashback(true);
                            }}
                            rel="noreferrer"
                          >
                            Cập nhật thông tin
                          </a>
                        </p>

                        {exam.end_register_at >
                          moment().format('YYYY-MM-DD HH:mm:ss') && (
                          <p className="m-0">
                            <br />
                            Nếu có nguyện vọng tiếp tục tham dự thi và không
                            nhận hoàn tiền, hãy chọn địa điểm thi:
                            <br />
                            <Button
                              type="primary"
                              size="small"
                              onClick={showModal}
                              key="1"
                            >
                              <small>Chọn địa điểm thi</small>
                            </Button>
                          </p>
                        )}
                      </>
                    )}
                  {!!isOpenFormCashback && (
                    <RegisterCashback
                      examPlan={examPlan}
                      onCancel={() => {
                        setIsOpenFormCashback(false);
                      }}
                      onSuccess={() => {
                        getData();
                        setIsOpenFormCashback(false);
                      }}
                    />
                  )}
                </>
              )}

              {!!examPlan?.overall_score && (
                <div
                  style={{
                    background: '#ce162810',
                    padding: 15,
                    width: '90%',
                    borderRadius: 10,
                  }}
                >
                  <Row>
                    <Col span={9} className="text-center">
                      <b> Điểm TSA</b>
                      <br />
                      <b style={{ color: '#ce1628', fontSize: 30 }}>
                        {examPlan?.overall_score}
                      </b>
                    </Col>
                    <Col span={15}>
                      {!!examPlan?.component_score && (
                        <>
                          {examPlan?.component_score?.is_correct_answer ==
                            1 && (
                            <>
                              <small>
                                <b>Số câu đúng:</b>
                              </small>
                              <br />
                            </>
                          )}
                          {Object.keys(examPlan?.component_score).map(
                            (c, cIndex) => {
                              if (c == 'is_correct_answer') {
                                return null;
                              }

                              return (
                                <small key={cIndex}>
                                  {c}: <b>{examPlan?.component_score[c]}</b>
                                  <br />
                                </small>
                              );
                            },
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  <p style={{ verticalAlign: 'center' }}></p>

                  {!!examPlan?.certification_url && (
                    <>
                      {!isRegisterReciveCer ? (
                        <>
                          <Button type="primary" block>
                            <a
                              href={examPlan?.certification_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              XEM CHỨNG CHỈ
                            </a>
                          </Button>
                          {exam.expired_time_receive_cer && (
                            <>
                              <a
                                className="ant-btn ant-btn-link u-link pl-0 mt-2"
                                onClick={() => {
                                  openFormReceiveCer();
                                }}
                                rel="noreferrer"
                              >
                                {examPlan.info_receive_cer
                                  ? 'Sửa thông tin nhận bản cứng chứng chỉ'
                                  : 'Đăng ký nhận bản cứng chứng chỉ của đợt thi'}
                              </a>
                              <br />

                              <small className="mt-0">
                                Hạn cuối tiếp nhận đăng ký:{' '}
                                {moment(exam?.expired_time_receive_cer).format(
                                  'HH:mm DD/MM/YYYY',
                                )}
                              </small>
                            </>
                          )}
                        </>
                      ) : (
                        <RegisterReciveCer
                          examPlan={examPlan}
                          setRegisterReciveCer={setRegisterReciveCer}
                        />
                      )}
                    </>
                  )}

                  {!!exam?.edit_profile_request_url && (
                    <>
                      <>
                        <p className="mt-2 mb-0 ">
                          <a
                            href={exam?.edit_profile_request_url}
                            target="_blank"
                          >
                            <small>
                              <u>Yêu cầu đính chính thông tin</u> (mã xác thực{' '}
                              <b>{examPlan.id}</b>)
                            </small>
                          </a>
                        </p>
                      </>
                    </>
                  )}

                  {examPlan?.status === 1 && !!examPlan?.note && (
                    <p className="mt-2 mb-0">
                      <small
                        dangerouslySetInnerHTML={{
                          __html: examPlan?.note || '',
                        }}
                      ></small>
                    </p>
                  )}
                </div>
              )}

              {!examPlan?.overall_score && examPlan?.note && (
                <div style={{ paddingRight: 15 }}>
                  <p
                    style={{
                      background: '#ce162810',
                      padding: 15,
                    }}
                  >
                    {examPlan?.note}
                  </p>
                </div>
              )}
            </Col>
            <Col xs={24} md={12}>
              <p>
                <b>Thông tin dự thi</b>
              </p>
              {examPlan?.status === -1 && (
                <>
                  <p>
                    {examPlan?.note ||
                      'Thông tin đăng ký dự thi của bạn đã bị từ chối'}
                  </p>
                </>
              )}

              {(examPlan === false ||
                examPlan?.status == 0 ||
                examPlan?.status == 1) && (
                <>
                  <p>
                    <span>Hội đồng thi:</span>{' '}
                    {examPlan?.test_site_name || <i>Chưa có thông tin</i>}
                  </p>
                  <p>
                    <span>Thời gian làm thủ tục:</span>{' '}
                    {examPlan?.checkin_time ? (
                      moment(examPlan?.checkin_time).format('HH:mm DD/MM/YYYY')
                    ) : (
                      <i>Chưa có thông tin</i>
                    )}
                  </p>
                  {!!examPlan?.enter_room_time && (
                    <p>
                      <span>Thời gian vào phòng thi:</span>{' '}
                      {examPlan?.enter_room_time ? (
                        moment(examPlan?.enter_room_time).format(
                          'HH:mm DD/MM/YYYY',
                        )
                      ) : (
                        <i>Chưa có thông tin</i>
                      )}
                    </p>
                  )}
                  <p>
                    <span>Thời gian bắt đầu thi:</span>{' '}
                    {examPlan?.start_time ? (
                      moment(examPlan?.start_time).format('HH:mm DD/MM/YYYY')
                    ) : (
                      <i>Chưa có thông tin</i>
                    )}
                  </p>
                  <p>
                    <span>Phòng thi:</span>{' '}
                    {examPlan?.room_name || <i>Chưa có thông tin</i>}
                  </p>
                  <p>
                    <span>Số báo danh:</span>{' '}
                    {examPlan?.student_number || <i>Chưa có thông tin</i>}
                  </p>
                  {!!examPlan?.inform_url && (
                    <p>
                      <span>Giấy báo dự thi:</span>{' '}
                      <a
                        href={examPlan?.inform_url}
                        className="text-primary"
                        target="_blank"
                      >
                        Xem tại đây
                      </a>
                    </p>
                  )}
                  {!!examPlan?.test_site_name || (
                    <p>
                      <i>
                        Chi tiết địa điểm và thời gian thi sẽ được cập nhật sau
                        khi có lịch thi cụ thể.
                      </i>
                    </p>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Modal
            title="Chọn/Đổi địa điểm thi"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
          >
            {!!listTestSite && listTestSite.length > 0 && (
              <>
                <div className="">
                  {listTestSite.map((testSite, testSiteIndex) => {
                    let color = '#00ab1d';
                    let percent = Math.round(
                      (testSite.count_test_taker * 100) /
                        testSite.max_test_taker,
                    );

                    if (percent > 90) {
                      color = '#f30000';
                    } else if (percent > 80) {
                      color = '#f33800';
                      percent = percent + 9;
                    } else if (percent > 60) {
                      percent = percent + 15;
                      color = '#f37b00';
                    } else if (percent > 40) {
                      percent = percent + 20;
                      color = '#f3a800';
                    } else if (percent > 20) {
                      color = '#9ccf00';
                      percent = percent + 20;
                    } else if (percent > 1) {
                      percent = percent + 10;
                    }

                    return (
                      <Row key={testSiteIndex}>
                        <Col span={12} className="d-flex align-items-center">
                          <b style={{ color: '#333' }}>{testSite.name}</b>
                        </Col>
                        <Col span={8}>
                          <span className="ml-2 float-right">
                            <span className="mr-2">
                              {testSite.max_test_taker <=
                              testSite.count_test_taker ? (
                                'Đã hết chỗ'
                              ) : (
                                <>
                                  {/* Còn{' '}
                                {testSite.max_test_taker -
                                  testSite.count_test_taker >
                                0
                                  ? testSite.max_test_taker -
                                    testSite.count_test_taker
                                  : 0}{' '}
                                chỗ */}
                                </>
                              )}
                            </span>
                          </span>

                          <Progress
                            trailColor="#ccc"
                            strokeColor={color}
                            className=" mb-3"
                            percent={percent}
                            showInfo={false}
                          />
                        </Col>
                        <Col
                          className="d-flex align-items-center pl-3"
                          span={4}
                        >
                          {examPlan.test_site_id == testSite.id ||
                          testSite.count_test_taker >=
                            testSite.max_test_taker ? (
                            <Button disabled={true} size="small" type="link">
                              {examPlan.test_site_id == testSite.id
                                ? 'Đã chọn'
                                : 'Chọn'}
                            </Button>
                          ) : (
                            <Popconfirm
                              title="Bạn có chắc chắn muốn chọn địa điểm thi?"
                              onConfirm={() =>
                                handleChangeTestSite(testSite.id)
                              }
                              onCancel={() => {}}
                              okText="Xác nhận"
                              cancelText="Bỏ qua"
                            >
                              <Button disabled={false} size="small" type="link">
                                Chọn
                              </Button>
                            </Popconfirm>
                          )}
                        </Col>
                        <hr />
                      </Row>
                    );
                  })}
                </div>
              </>
            )}
          </Modal>
        </>
      )}
    </Modal>
  );
};

export default ExamPlanDetail;
