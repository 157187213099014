import React, { useState, useEffect } from 'react';
import {
  PhoneOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Space,
  Tag,
  Row,
  Button,
  Typography,
  Modal,
  Col,
  message,
  Skeleton,
  Card,
  Tooltip,
  Radio,
  Progress,
  Popconfirm,
  Form,
  Input,
  Select,
} from 'antd';
import { getCities, saveRegisterCer } from '../service';
import {
  filterSelectOptions,
  getErrorMessages,
  isVietnamesePhoneNumber,
} from 'app/utils/functions';
const RegisterReciveCer = ({ examPlan, setRegisterReciveCer }) => {
  const [province, setProvince] = useState({});
  const getCitiesData = async (type = '', child, allowOther = false) => {
    const response = await getCities(type);
    if (response.data.code == 200) {
      let cities = [];
      cities = response.data.data.cities.map((i) => ({
        value: i.code || i.provinceid,
        label: i.name,
      }));
      cities = cities.filter((item) => Number(item.value) > 0);
      setProvince({ data: cities });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };
  useEffect(() => {
    if (province == {}) {
      return;
    }
    getCitiesData(1, 'data');
  }, []);

  const onFinish = async (_value) => {
    let name_city_choice = province.data.find(
      (e) => e.value == _value.province_dctt,
    );
    _value.name_city = name_city_choice.label;
    _value.exam_plan_id = examPlan.id;
    let res = await saveRegisterCer(_value);
    if (res.data?.code == 200) {
      setRegisterReciveCer(false);
      message.success('Cập nhật thành công');
    } else {
      message.error(getErrorMessages(res.data));
    }
  };
  return (
    <>
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={examPlan.info_receive_cer}
      >
        <Row>
          <Col xs={24} className="">
            <Form.Item
              label={<span>Họ và Tên</span>}
              name="name"
              normalize={(value) => {
                if (value.trim() === '') {
                  return '';
                }

                return value.toLocaleUpperCase();
              }}
              rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}
            >
              <Input
                // disabled={!!this.props.view}
                tabIndex={1}
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} className="">
            <Form.Item
              label={<span>Số điện thoại</span>}
              name="phone"
              rules={[
                { required: true, message: 'Vui lòng nhập số điện thoại' },
                () => ({
                  validator(_, value) {
                    if (!value || isVietnamesePhoneNumber(value)) {
                      return Promise.resolve();
                    }

                    return Promise.reject('Số điện thoại không đúng định dạng');
                  },
                }),
              ]}
            >
              <Input
                // disabled={!!this.props.view}
                tabIndex={1}
                type="number"
                prefix={<PhoneOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={<span>Tỉnh/TP</span>}
              name="province_dctt"
              rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/TP' }]}
            >
              <Select
                // disabled={!!this.props.view}
                showSearch
                placeholder="Chọn"
                notFoundContent="Không tìm thấy kết quả"
                // autoComplete="province_dctt"
                options={province.data}
                // onChange={(_) =>
                // this.resetFields(['district_dctt', 'ward_dctt'])
                // }
                filterOption={filterSelectOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} className="">
            <Form.Item
              label={<span>Địa chỉ nhận hồ sơ</span>}
              name="address"
              normalize={(value) => {
                if (value.trim() === '') {
                  return '';
                }
                return value;
                // return value.toLocaleUpperCase();
              }}
              rules={[
                { required: true, message: 'Vui lòng nhập địa chỉ nhận hồ sơ' },
              ]}
            >
              <Input
                // disabled={!!this.props.view}
                tabIndex={1}
                prefix={<HomeOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button block>
            <a
              onClick={() => {
                setRegisterReciveCer(false);
                return;
              }}
              rel="noreferrer"
            >
              Hủy
            </a>
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: '5px' }}
            block
          >
            <a
              onClick={() => {
                return;
              }}
              rel="noreferrer"
            >
              Xác nhận
            </a>
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default RegisterReciveCer;
