import ROOT_PATHS from 'root-paths';

const PATHS = {
  ...ROOT_PATHS,
  login: '/dang-nhap',
  register: '/dang-ky',
  exam: '/dk',
  examDetail: '/dk/:examCode',
  examDetailContinue: '/dk/:examCode/:id',
  changePassword: '/doi-mat-khau',
  listExam: '/dang-ky-thi',
  xttn: '/xttn',

  classes: '/lop-hoc',
  classesDetail: '/lop-hoc/:id',
  testTaker: '/bai-thi',
  testTakerDetail: '/bai-thi/:id',
  profile: '/tai-khoan',
  connective: '/lien-ket',
  reference: '/r/:referenceId',
};

export default PATHS;
