import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { saveRegisterProfileUrl, getRegisterProfileUrl } from '../service';
import {
  Button,
  Form,
  Input,
  Select,
  Card,
  message,
  DatePicker,
  InputNumber,
} from 'antd';
import { filterSelectOptions, getErrorMessages } from 'app/utils/functions';
import config from 'app/config';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import subjects from '../subjects';
import TextArea from 'antd/lib/input/TextArea';
import ExamTypes from 'modules/Exam/Components/ExamTypes';

// const { RangePicker } = DatePicker;

const { Option } = Select;

class AdminExamForm extends Component {
  formRef = React.createRef();

  state = {
    tooltipOpen: false,
    id: null,
    loading: false,
    item: {},
    saveLoading: false,
  };

  componentDidMount() {
    const id =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null;
    if (id) {
      this.setState({ id });
      this.getRegisterProfileUrlInfo(id);
    }
  }

  getRegisterProfileUrlInfo = async (id) => {
    this.setState({ loading: true });
    const response = await getRegisterProfileUrl(id);
    if (response.data.code == 200) {
      const item = response.data.data.item;
      item.status = `${item.status}`;
      item.type = `${item.type}`;

      item.start_register_at = moment(item.start_register_at);
      item.end_register_at = moment(item.end_register_at);
      item.start_at = moment(item.start_at);
      item.end_at = moment(item.end_at);
      item.configs = JSON.stringify(item.configs, null, '\t');

      try {
        item.test_urls = JSON.parse(item.test_urls);
      } catch (error) {
        item.test_urls = [];
      }

      this.setState({ item }, () => {
        this.formRef.current.setFieldsValue(item);
      });
    } else {
      message.error('Có lỗi xảy ra, vui lòng thử lại');
      this.props.history.push('/admin/exams');
    }

    this.setState({ loading: false });
  };

  back = () => {
    this.props.history.push('/admin/exams');
  };

  onFinish = async (values) => {
    this.setState({ saveLoading: true });
    const data = values;

    if (this.state.id) {
      data['id'] = this.state.id;
      delete data.service_code;
    }

    data.status = parseInt(data.status);
    data.type = parseInt(data.type);

    data.start_register_at = moment(data.start_register_at).format(
      'YYYY-MM-DD',
    );

    data.end_register_at = moment(data.end_register_at).format('YYYY-MM-DD');

    data.start_at = moment(data.start_at).format('YYYY-MM-DD');

    data.end_at = moment(data.end_at).format('YYYY-MM-DD');

    data.test_urls = JSON.stringify(data.test_urls || []);

    const response = await saveRegisterProfileUrl(values);
    if (response.data.code == 200) {
      this.setState(
        {
          saveLoading: false,
        },
        () => {
          this.props.history.push('/admin/exams');
        },
      );
    } else {
      this.setState(
        {
          saveLoading: false,
        },
        () => {
          if (
            typeof response.data.errors != 'undefined' &&
            Object.keys(response.data.errors).length > 0
          ) {
            Object.values(response.data.errors).forEach((element) => {
              message.error(element);
            });
          } else {
            message.error(getErrorMessages(response.data));
          }
        },
      );
    }
  };

  changeVal = (_, allValues) => {
    this.setState({
      item: allValues,
    });
  };

  render() {
    const { id, loading, saveLoading, item } = this.state;
    const isEdit = id !== null;

    return (
      <Container className="page-exam">
        <Row>
          <Col lg={12}>
            <div className="social__header">
              <div className="social__title">
                <span className="title-classes title-classes-light">
                  {isEdit ? 'Sửa' : 'Thêm'} kỳ thi
                </span>
              </div>
              <hr className="line mt-3" />
            </div>
          </Col>
          <Col lg={8}>
            <Card>
              <Form
                layout="vertical"
                name="basic"
                className="item-form"
                initialValues={item}
                onValuesChange={this.changeVal}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
                autoComplete="off"
              >
                {loading && <>...</>}
                {!loading && (
                  <>
                    <Form.Item
                      name="name"
                      label="Tên kỳ thi"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập tên kỳ thi',
                        },
                      ]}
                    >
                      <Input placeholder="Nhập tên kỳ thi" />
                    </Form.Item>

                    <Form.Item
                      name="url"
                      label="Đường dẫn kỳ thi"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập đường dẫn',
                        },
                      ]}
                      help="Ví dụ sử dụng cấu trúc: kt-[Năm thi]-[STT kỳ thi]"
                    >
                      <Input disabled={isEdit} placeholder="Ví dụ: kt-21-001" />
                    </Form.Item>

                    <Row>
                      <Col lg={6}>
                        <Form.Item
                          name="type"
                          label="Tổ chức thi"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn hình thức tổ chức thi',
                            },
                          ]}
                        >
                          <Select disabled={isEdit}>
                            <Option value="">-- Loại bài thi --</Option>
                            <Option value={ExamTypes.ONLINE}>Online</Option>
                            <Option value={ExamTypes.OFFLINE}>Offline</Option>
                            <Option value={ExamTypes.ONLINE_2}>
                              Online yêu cầu hồ sơ
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* {item.type == 1 && ( */}
                    <Row>
                      <Col lg={6}>
                        <Form.Item
                          label="Thời gian bắt đầu đăng ký"
                          name="start_register_at"
                          rules={[
                            {
                              required: true,
                              message:
                                'Bạn chưa nhập thời gian bắt đầu đăng ký',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  getFieldValue('type') == 1 &&
                                  getFieldValue('end_register_at') <= value
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      'Thời gian bắt đầu đăng ký phải nhỏ hơn thời gian hết hạn đăng ký',
                                    ),
                                  );
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            // showTime
                            format="DD/MM/YYYY HH:mm"
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item
                          label="Thời gian kết thúc đăng ký"
                          name="end_register_at"
                          rules={[
                            {
                              required: true,
                              message:
                                'Bạn chưa nhập thời gian hết hạn đăng ký',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  getFieldValue('type') == 1 &&
                                  getFieldValue('start_register_at') >= value
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      'Thời gian hết hạn đăng ký phải lớn hơn thời gian bắt đầu đăng ký',
                                    ),
                                  );
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            // showTime
                            format="DD/MM/YYYY HH:mm"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* )} */}
                    <Row>
                      <Col lg={6}>
                        <Form.Item
                          label="Thời gian dự kiến bắt đầu thi"
                          name="start_at"
                          rules={[
                            {
                              required: true,
                              message:
                                'Bạn chưa nhập thời gian dự kiến bắt đầu thi',
                            },
                            /* ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (getFieldValue('end_at') <= value) {
                                  return Promise.reject(
                                    new Error(
                                      'Thời gian dự kiến bắt đầu thi phải nhỏ hơn thời gian dự kiến kết thúc thi',
                                    ),
                                  );
                                }

                                return Promise.resolve();
                              },
                            }), */
                          ]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            // showTime
                            format="DD/MM/YYYY HH:mm"
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item
                          label="Thời gian dự kiến thi đến"
                          name="end_at"
                          rules={[
                            {
                              required: true,
                              message:
                                'Bạn chưa nhập thời gian dự kiến thi đến',
                            },
                            /* ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (getFieldValue('start_at') >= value) {
                                  return Promise.reject(
                                    new Error(
                                      'Thời gian dự kiến kết thúc thi phải lớn hơn thời gian dự kiến bắt đầu thi',
                                    ),
                                  );
                                }

                                return Promise.resolve();
                              },
                            }), */
                          ]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            // showTime
                            format="DD/MM/YYYY HH:mm"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <Form.Item
                          name="profile_type"
                          label="Loại hồ sơ"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn loại hồ sơ',
                            },
                          ]}
                        >
                          <Select disabled={isEdit}>
                            <Option value="">-- Chọn loại hồ sơ --</Option>
                            <Option value="BASIC">Cơ bản</Option>
                            <Option value="HUST">Đầy đủ</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <Form.Item
                          name="status"
                          label="Trạng thái"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn trạng thái',
                            },
                          ]}
                        >
                          <Select>
                            <Option value=""> -- Chọn trạng thái -- </Option>
                            <Option value="1">Hoạt động</Option>
                            <Option value="2">Hoạt động - ẩn</Option>
                            <Option value="3">Hết hạn</Option>
                            <Option value="0">Dừng hoạt động</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item
                          name="amount"
                          label="Giá"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập giá',
                            },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            max={2000000}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item name="configs" label="Cấu hình">
                          <TextArea rows={20} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <p>Đường dẫn thi:</p>

                    <Form.List name="test_urls">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Row key={field.key} align="baseline">
                              <Col md={3}>
                                <Form.Item
                                  {...field}
                                  label="Môn"
                                  name={[field.name, 'subject']}
                                  fieldKey={[field.fieldKey, 'subject']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Vui lòng chọn môn',
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Chọn"
                                    options={subjects}
                                    notFoundContent="Không tìm thấy kết quả"
                                    filterOption={filterSelectOptions}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8}>
                                <Form.Item
                                  {...field}
                                  label="Đường dẫn thi"
                                  name={[field.name, 'url']}
                                  fieldKey={[field.fieldKey, 'url']}
                                  rules={[
                                    {
                                      required: true,
                                      type: 'url',
                                      message: 'Vui nhập đường dẫn thi',
                                    },
                                  ]}
                                >
                                  <Input placeholder="Nhập đường dẫn thi" />
                                </Form.Item>
                              </Col>
                              <Col md={1}>
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              </Col>
                            </Row>
                          ))}

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Thêm đường dẫn thi
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                    {/* <Form.Item label="Mô tả" name="description">
                      <Input.TextArea rows={4} />
                    </Form.Item> */}

                    <Col lg={12}>
                      <Row style={{ justifyContent: 'space-between' }}>
                        <Button
                          type="default"
                          size="large"
                          onClick={() => this.back()}
                        >
                          Hủy
                        </Button>
                        <Button type="primary" htmlType="submit" size="large">
                          {saveLoading ? (
                            <div>
                              <img
                                alt=""
                                className="icon-loading"
                                src={config.getLoadingDefault()}
                              />
                              Đang gửi
                            </div>
                          ) : (
                            <span>Lưu lại</span>
                          )}
                        </Button>
                      </Row>
                    </Col>
                  </>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AdminExamForm);
