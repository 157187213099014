import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Badge, Collapse, message, Button, Card, Select, Tooltip } from 'antd';
import {
  RightOutlined,
  MinusOutlined,
  PlusOutlined,
  QuestionOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import { Radar, Doughnut } from 'react-chartjs-2';
import Scroll from 'react-scroll';
import config from '../../../app/config';
import {
  getWindowFunction,
  colorChart,
  colorChartList,
  getArea,
  getTimeDifferences,
  TestBlueprintSortItems,
} from '../../../app/utils/functions';
import { getDetailExam } from '../service';
import ViewItem from '../Components/ViewItem';
import BreadcrumbX from '../../Common/BreadcrumbX';

const { Panel } = Collapse;
const { Option } = Select;
const { scroller } = Scroll;
class TestTakerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
      titleRadar: '',
      loading: true,
      viewDetail: false,
      display: 1,
      scrollStatus: false,
      mark: false,
      testOutline: [],
      testForm: [],
      testFormItems: [],
      testFormChildItems: [],
      answerTaker: [],
      testTaker: [],
      testTakerGroup: {},
      testBlueprints: [],
      testBlueprintsReport: [],
      lstChangeChart: [],
      testBlueprintsPoint: [],
      blueprints_data_report: [],
      activeTab: '1',
      reportData: {},
      point: [],
      test_taker_mark_histogram: [],
      showInstruction: false,
      disableReloadMathjax: false,
      tabActive: 1,
      accordion: [true],
      hideParentRadar: true,
      chartRadar: {
        labels: [],
        datasets: [],
      },
      dataPie: {
        labels: ['Câu đúng', 'Câu sai', 'Câu chưa làm'],
        totalAnwserChoice: 0,
        totalAnwserChoiceCorrect: 0,
        totalAnwserPoint: 0,
        maxPoint: 0,
        datasets: [
          {
            data: [],
            backgroundColor: ['#4DD077', '#FF4D4F', '#E8E8E8'],
          },
        ],
      },
      dataPieEssay: {
        labels: ['Câu đã trả lời', 'Câu chưa trả lời'],
        totalAnwserDone: 0,
        totalAnwserEssay: 0,
        totalAnwserMaxPoint: 0,
        totalAnwserPoint: 0,
        maxPoint: 0,
        datasets: [
          {
            data: [],
            backgroundColor: ['#4DD077', '#FF4D4F'],
          },
        ],
      },
      listRadarChart: [],
    };
  }

  componentDidMount() {
    this.getDataDetailExam(this.props.match.params.id);
    window.addEventListener('scroll', () => {
      let scrollStatus = false;
      if (window.scrollY >= 650) {
        scrollStatus = true;
      }
      this.setState({ scrollStatus });
    });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleViewDetail = () => {
    this.setState({
      viewDetail: !this.state.viewDetail,
    });
  };

  getDataDetailExam = async (id) => {
    const response = await getDetailExam(id);
    const { listRadarChart, lstChangeChart } = this.state;
    const self = this;
    if (response.data.code == 200) {
      let testBlueprints = [];
      try {
        testBlueprints = response.data.data.test_form.test_blueprints_data
          ? JSON.parse(response.data.data.test_form.test_blueprints_data)
          : [
              {
                id: null,
              },
            ];
      } catch (e) {}
      const testBlueprintsReport = TestBlueprintSortItems(testBlueprints);
      const testBlueprintsPoint =
        response.data.data.blueprints_data_report.test_blue_print;
      const { accordion, dataPie, dataPieEssay } = this.state;
      const items_report = testBlueprintsReport.filter(
        (item) => testBlueprintsPoint[item.id],
      );

      testBlueprintsReport.forEach((testBlueprint) => {
        const check_parent = items_report.filter(
          (item) => item.parent_id == testBlueprint.id,
        );
        if (
          (testBlueprint.level == 0 || testBlueprint.level == 1) &&
          check_parent.length > 0
        ) {
          if (testBlueprint.level == 1) {
            const parent_item = testBlueprintsReport.find(
              (item) => item.id == testBlueprint.parent_id,
            );
            if (parent_item && !accordion[parent_item.id]) {
              items_report.push(parent_item);
              accordion[parent_item.id] = true;
              lstChangeChart.push(parent_item);
            }
          }
          items_report.push(testBlueprint);
          accordion[testBlueprint.id] = true;
          lstChangeChart.push(testBlueprint);
        }
      });

      items_report.sort((a, b) => {
        if (a.order === b.order) {
          return a.id - b.id;
        }

        return a.order > b.order ? 1 : -1;
      });
      // Doughnut câu đúng câu sai
      let count_eassay_done = 0;
      let max_point_essay = 0;
      let point_essay = 0;
      let count_total_anwser_essay = 0;

      let count_correct_choice = 0;
      let count_wrong_choice = 0;
      let count_cancel_choice = 0;
      let count_total_anwser_choice = 0;
      let point_choice = 0;
      let max_point_choice = 0;
      const answerTaker = [];

      Object.entries(response.data.data.answerTaker).forEach(([, value]) => {
        if(value.type == 3) {
          if (value.data) {
            count_eassay_done ++;
          }
          point_essay += Number(value.point);
          max_point_essay += Number(value.point_max);
          count_total_anwser_essay ++;

          return;
        }
        // if (value.type == 1) {
          if (!value.data) {
            count_cancel_choice++;
          } else if (value.correct_answer == 1) {
            count_correct_choice++;
          } else {
            count_wrong_choice++;
          }
          
          point_choice += Number(value.point)
          count_total_anwser_choice++;
          max_point_choice += Number(value.point_max)
        // }
        answerTaker[value.item_id] = value;
      });
      console.log('point_essay * 10 / (max_point_essay + max_point_choice)', point_essay * 10 / (max_point_essay + max_point_choice), max_point_essay + max_point_choice)
      dataPieEssay.totalAnwserDone = count_eassay_done;
      dataPieEssay.totalAnwserMaxPoint = max_point_essay;
      dataPieEssay.totalAnwserPoint = point_essay * 10 / (max_point_essay + max_point_choice);
      dataPieEssay.totalAnwserEssay = count_total_anwser_essay;
      dataPieEssay.datasets[0].data = [
        count_eassay_done,        
        count_total_anwser_essay - count_eassay_done
      ];

      dataPie.count_correct_choice =  count_correct_choice;
      dataPie.totalAnwserPoint =  point_choice * 10 / (max_point_essay + max_point_choice);
      dataPie.totalAnwserChoice =  count_total_anwser_choice;
      dataPie.datasets[0].data = [
        response.data.data.test_taker.correct_answer_count || 0,        
        count_wrong_choice,
        count_cancel_choice,
      ];

      self.setState(
        {
          lstChangeChart,
          loading: false,
          testOutline: response.data.data.test_outline,
          testForm: response.data.data.test_form,
          testFormItems: response.data.data.items,
          testFormChildItems: response.data.data.items_children,
          testTaker: response.data.data.test_taker,
          testTakerGroup: response.data.data.test_taker_group,
          testBlueprints,
          testBlueprintsReport: items_report,
          accordion,
          testBlueprintsPoint,
          dataPie,
          answerTaker,
          blueprints_data_report:
            response.data.data.blueprints_data_report.radar,
        },
        () => {
          // Biểu đồ radar theo đvkt
          const chartRadar = {
            labels: [],
            datasets: [{ data: [] }],
          };
          const blueprint_parent = [];
          Object.entries(
            response.data.data.blueprints_data_report.radar,
          ).forEach(([key, value]) => {
            const check_item = items_report.filter((item) => item.id == key);
            if (check_item.length > 0) {
              blueprint_parent.push(key);
              chartRadar.labels.push(value.name);
              chartRadar.datasets[0].data.push(value.percent.toFixed(0));
            }
          });
          if (
            chartRadar.labels &&
            chartRadar.labels.length <= 1 &&
            blueprint_parent &&
            blueprint_parent[0]
          ) {
            this.changeRadar(blueprint_parent[0]);
          } else {
            chartRadar.datasets[0].label =
              response.data.data.test_taker_group.name;
            chartRadar.datasets[0].borderColor =
              chartRadar.datasets[0].backgroundColor =
              chartRadar.datasets[0].pointBackgroundColor =
              chartRadar.datasets[0].pointBorderColor =
              chartRadar.datasets[0].borderColor =
                colorChart()[
                  getWindowFunction(
                    parseInt(response.data.data.test_taker.score) * 2,
                    [0, 7, 10, 13, 16],
                  )
                ];
            listRadarChart[id] = chartRadar;
            this.setState({
              chartRadar,
              hideParentRadar: false,
              listRadarChart,
            });
          }
        },
      );
    } else {
      message.error(response.data.message);
      setTimeout(() => {
        // window.location = '/';
      }, 2000);
    }
  };

  formatTimeSpent = (row) => {
    const date1 = moment(row.started_at).format('MMMM DD, YYYY HH:mm:ss');
    const date2 = moment(row.submitted_at).format('MMMM DD, YYYY HH:mm:ss');

    return getTimeDifferences(date1, date2);
  };

  toggleAccordion = (tab) => {
    const { accordion } = this.state;
    accordion[tab] = !accordion[tab];
    this.setState({
      accordion,
    });
  };

  renderTestBlueprintBlock(testBlueprint) {
    const testBlueprintId = testBlueprint.id;
    const items_child = this.state.testBlueprintsReport.filter(
      (item) => item.parent_id == testBlueprintId,
    );
    if (items_child.length > 0) {
      if (testBlueprint.level != 0) {
        return (
          <div className="child-group" key={testBlueprint.id}>
            <div
              className="item-blueprint item-blueprint-child flex space-between"
              color="primary"
            >
              <span className="blueprint-name">{testBlueprint.name} </span>
              <div className="fl-r percent-blueprint">
                {config
                  .getConfig('item_levels')
                  .map((itemLevel, itemLevelIndex) => {
                    const percent =
                      this.state.testBlueprintsPoint[testBlueprint.id] &&
                      this.state.testBlueprintsPoint[testBlueprint.id][
                        itemLevel.value
                      ] &&
                      this.state.testBlueprintsPoint[testBlueprint.id][
                        itemLevel.value
                      ].percent >= 0
                        ? `${this.state.testBlueprintsPoint[testBlueprint.id][
                            itemLevel.value
                          ].percent.toFixed(0)}%`
                        : '-';

                    return (
                      <span
                        key={itemLevelIndex}
                        className="text-center item-quantity"
                      >
                        {percent}
                      </span>
                    );
                  })}
              </div>
            </div>
            {this.state.testBlueprintsReport.map((testBlueprintChild) => {
              if (testBlueprintChild.parent_id == testBlueprint.id) {
                return this.renderTestBlueprintBlock(testBlueprintChild);
              }

              return '';
            })}
          </div>
        );
      }

      return (
        <Panel
          className="parent-blueprint b-r-8 mb-2"
          header={testBlueprint.name}
          key={testBlueprint.id}
        >
          {this.state.testBlueprintsReport.map((testBlueprintChild) => {
            if (testBlueprintChild.parent_id == testBlueprint.id) {
              return this.renderTestBlueprintBlock(testBlueprintChild);
            }

            return '';
          })}
        </Panel>
      );
    }

    return (
      <div
        className="item-blueprint blueprint-child item-blueprint-child flex space-between"
        color="primary"
        key={testBlueprint.id}
      >
        <span className="blueprint-name">{testBlueprint.name} </span>
        <div className="fl-r percent-blueprint">
          {config.getConfig('item_levels').map((itemLevel, itemLevelIndex) => {
            const percent =
              this.state.testBlueprintsPoint[testBlueprint.id] &&
              this.state.testBlueprintsPoint[testBlueprint.id][
                itemLevel.value
              ] &&
              this.state.testBlueprintsPoint[testBlueprint.id][itemLevel.value]
                .percent >= 0
                ? `${this.state.testBlueprintsPoint[testBlueprint.id][
                    itemLevel.value
                  ].percent.toFixed(0)}%`
                : '-';

            return (
              <span key={itemLevelIndex} className="text-center item-quantity">
                {percent}
              </span>
            );
          })}
        </div>
      </div>
    );
  }

  renderTestBlueprintDesc(testBlueprint, total = 0) {
    const testBlueprintId = testBlueprint.id;
    const items_child = this.state.testBlueprintsReport.filter(
      (item) => item.parent_id == testBlueprintId,
    );
    if (items_child.length > 0) {
      items_child.forEach((testBlueprintChild) => {
        total = this.renderTestBlueprintDesc(testBlueprintChild, total);
      });

      return total;
    }
    const items = this.state.testFormItems.filter(
      (item) => item.test_blueprint_id == testBlueprint.id,
    );
    total += items.length;

    return total;
  }

  changeRadar = (id) => {
    const { listRadarChart } = this.state;
    let chartRadar = {
      labels: [],
      datasets: [],
    };
    const items_child = this.state.testBlueprintsReport.filter(
      (item) => item.parent_id == id,
    );
    if (items_child.length == 0) {
      if (listRadarChart[`${this.props.match.params.id}`]) {
        chartRadar = listRadarChart[`${this.props.match.params.id}`];
      }
    } else {
      items_child.forEach((item) => {
        chartRadar.labels.push(item.name);
      });

      config.getConfig('item_levels').forEach((itemLevel) => {
        const datasets = [];
        items_child.forEach((value) => {
          const percent =
            this.state.testBlueprintsPoint[value.id] &&
            this.state.testBlueprintsPoint[value.id][itemLevel.value] &&
            this.state.testBlueprintsPoint[value.id][itemLevel.value].percent >=
              0
              ? this.state.testBlueprintsPoint[value.id][
                  itemLevel.value
                ].percent.toFixed(0)
              : 0;
          datasets.push(percent);
        });
        chartRadar.datasets.push({
          label: itemLevel.name,
          data: datasets,
          pointStyle: 'circle',
          pointBorderWidth: 2,
          datasets: getArea(datasets),
        });
      });
      if (chartRadar.labels.length == 1) {
        chartRadar.labels.push('');
        chartRadar.labels.push('');
        chartRadar.labels.push('');
      } else if (chartRadar.labels.length == 2) {
        chartRadar.labels.push('');
        chartRadar.labels.push('');
      }

      chartRadar.datasets.sort((a, b) => {
        return getArea(a.data) - getArea(b.data);
      });

      chartRadar.datasets = chartRadar.datasets.map(
        (itemLevel, itemLevelIndex) => {
          itemLevel.backgroundColor =
            colorChartList('hover')[itemLevelIndex] || colorChartList()[6];
          itemLevel.borderColor =
            colorChartList()[itemLevelIndex] || colorChartList()[6];
          itemLevel.pointBackgroundColor =
            colorChartList()[itemLevelIndex] || colorChartList()[6];
          itemLevel.pointBorderColor =
            colorChartList()[itemLevelIndex] || colorChartList()[6];

          return itemLevel;
        },
      );
    }
    this.setState({
      chartRadar,
    });
  };

  customExpandIcon = (props) => {
    if (props.isActive) {
      return <MinusOutlined />;
    }

    return <PlusOutlined />;
  };

  onMenuClick(itemId) {
    scroller.scrollTo(`ScrollToItem${itemId}`, {
      duration: 300,
      delay: 0,
      smooth: true,
      offset: -70, // Scrolls to element + 50 pixels down the page
    });
    this.showMenuItem(false);
  }

  showMenuItem = (fixed) => {
    this.setState({
      fixed,
    });
  };

  render() {
    let itemNum = 0;
    let itemNumRight = 0;
    const {
      fixed,
      testFormItems,
      answerTaker,
      scrollStatus,
      viewDetail,
      testTaker,
      hideParentRadar,
      titleRadar,
      dataPie,
      dataPieEssay,
    } = this.state;

    return (
      <>
        <Container className={testFormItems ? '' : 'view-customer'}>
          <Row>
            <Col>
              <BreadcrumbX
                location={this.props.location}
                extra={[{ path: '', name: this.state.testForm.name }]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="social__header">
                <div className="social__title">
                  <span className="title-classes">
                    {this.state.testForm && (
                      <span>
                        {this.state.testTakerGroup.name} -{' '}
                        {this.state.testForm.name}
                      </span>
                    )}
                  </span>
                  <div className="action-group-right">
                    {/* <Button type="primary" danger className="mr-2">
                    {testTaker.status == 36 && testTaker.score ? parseFloat(testTaker.score)+' điểm' : 'Đang chờ chấm'}
                  </Button> */}
                    {testFormItems && (
                      <Button
                        type="primary"
                        onClick={() => this.toggleViewDetail()}
                      >
                        {viewDetail ? 'Ẩn' : 'Xem'} đáp án
                      </Button>
                    )}
                  </div>
                </div>
                <hr className="line mt-3" />
              </div>
            </Col>
          </Row>
          {!viewDetail && (
            <>
              <Row>
                <Col xs="12" md="12" lg="12" xl="5" className="mb-2">
                  <Card
                    className="x-card card-doughnut"
                    title="Trắc nghiệm"
                    actions={
                      this.state.testFormItems && [
                        <a
                          /* eslint jsx-a11y/anchor-is-valid: "off" */
                          className="secondary"
                          onClick={() => this.toggleViewDetail()}
                        >
                          Xem đáp án{' '}
                          <RightOutlined className="vertical-align-middle" />{' '}
                        </a>,
                      ]
                    }
                  >
                    <div className="flex align-center box-exam-chart-point">
                      <div className="exam-chart-point">
                        <Doughnut
                          data={dataPie}
                          options={{
                            maintainAspectRatio: false, // Disable the default aspect ratio
                            responsive: true, // Make it responsive
                            legend: {
                              display: false,
                              position: 'bottom',
                            },
                            title: {
                              position: 'bottom',
                              display: true,
                              text: dataPie.count_correct_choice > 0
                                ? `${dataPie.count_correct_choice}/${dataPie.totalAnwserChoice || 0} câu`
                                : '0 câu',
                            },
                          }}
                        />
                        <span className="exam-point">
                          {dataPie.totalAnwserPoint >= 0 ? (
                            parseFloat(dataPie.totalAnwserPoint.toFixed(2))
                          ) : (
                            <Tooltip placement="top" title="Đang chờ chấm">
                              <QuestionOutlined />
                            </Tooltip>
                          )}
                        </span>
                      </div>
                      <ul className="legend-doughnut pl-0">
                        <li className="flex space-between">
                          <Badge color="#4DD077" text="Câu đúng" />{' '}
                          <b>
                            {dataPie.count_correct_choice
                              ? dataPie.count_correct_choice
                              : 0}
                          </b>
                        </li>
                        <li className="flex space-between">
                          <Badge color="#FF4D4F" text="Câu sai" />{' '}
                          <b>{dataPie.datasets[0].data[1] || 0}</b>
                        </li>
                        <li className="flex space-between">
                          <Badge color="#E8E8E8" text="Câu chưa làm" />{' '}
                          <b>{dataPie.datasets[0].data[2] || 0}</b>
                        </li>
                      </ul>
                    </div>
                  </Card>
                </Col>
                <Col xs="12" md="6" lg="6" xl="3" className="mb-2">
                  <Card
                    className="x-card card-doughnut card-doughnut-tl"
                    title="Tự luận"
                    actions={
                      this.state.testFormItems && [
                        <a
                          /* eslint jsx-a11y/anchor-is-valid: "off" */
                          className="secondary"
                          onClick={() => this.toggleViewDetail()}
                        >
                          Xem bài làm{' '}
                          <RightOutlined className="vertical-align-middle" />{' '}
                        </a>,
                      ]
                    }
                  >
                    {/* <i className="text-center">Đang phát triển</i> */}
                    <div className="exam-chart-point">
                        <Doughnut
                          data={dataPieEssay}
                          options={{
                            maintainAspectRatio: false, // Disable the default aspect ratio
                            responsive: true, // Make it responsive
                            legend: {
                              display: false,
                              position: 'bottom',
                            },
                            title: {
                              position: 'bottom',
                              display: true,
                              text: dataPieEssay.totalAnwserDone > 0
                                ? `${dataPieEssay.totalAnwserDone}/${dataPieEssay.totalAnwserEssay || 0} câu`
                                : '0 câu',
                            },
                          }}
                        />
                        <span className="exam-point">
                          {testTaker.status == 36 && dataPieEssay.totalAnwserPoint >= 0 ? (
                            parseFloat(dataPieEssay.totalAnwserPoint.toFixed(2))
                          ) : (
                            <Tooltip placement="top" title="Đang chờ chấm">
                              <QuestionOutlined />
                            </Tooltip>
                          )}
                        </span>
                      </div>
                  </Card>
                </Col>
                <Col xs="12" md="6" lg="6" xl="4">
                  <Card
                    className="x-card card-info-exam"
                    title="Thông tin đề thi"
                  >
                    <Row className="mb-2">
                      <Col xs="6">Mã dự thi</Col>
                      <Col xs="6">
                        <b>{testTaker.code}</b>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="6">Kíp thi</Col>
                      <Col xs="6">{this.state.testTakerGroup.name}</Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="6">Thời gian</Col>
                      <Col xs="6">
                        {testTaker.time_limit && testTaker.time_limit > 0
                          ? `${testTaker.time_limit} phút`
                          : 'Không giới hạn'}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="6">Thời gian làm bài thực tế</Col>
                      <Col xs="6">{this.formatTimeSpent(testTaker)}</Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="6">Số câu trắc nghiệm đúng</Col>
                      <Col xs="6">
                        {dataPie.count_correct_choice
                          ? `${dataPie.count_correct_choice}/${dataPie.totalAnwserChoice || 0}`
                          : 0}{' '}
                        câu
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="6">Tổng số câu</Col>
                      <Col xs="6">
                        {testTaker.total_items}{' '}
                        câu
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              {this.state.testBlueprintsReport.length > 0 && (
                <Row className="mt-4 mb-4">
                  <Col xs="12" md="12" lg="12" xl="5" className="mb-2">
                    <Card className="x-card" title="Đánh giá">
                      <div className="blue-print-label">
                        <div className="fl-r percent-blueprint">
                          <span className="text-center item-quantity">
                            Biết
                          </span>
                          <span className="text-center item-quantity">
                            Hiểu
                          </span>
                          <span className="text-center item-quantity">VD</span>
                          <span className="text-center item-quantity">VDC</span>
                        </div>
                      </div>
                      <Collapse
                        className="box-blueprint"
                        expandIcon={(props) => this.customExpandIcon(props)}
                      >
                        {this.state.testBlueprintsReport.map(
                          (testBlueprint) => {
                            if (testBlueprint.level == 0) {
                              return this.renderTestBlueprintBlock(
                                testBlueprint,
                              );
                            }

                            return '';
                          },
                        )}
                      </Collapse>
                    </Card>
                  </Col>
                  <Col xs="12" md="12" lg="12" xl="7">
                    <Card
                      className="x-card"
                      title={
                        <div className="flex">
                          <span className="title-radar-box">Biểu đồ</span>
                          {!hideParentRadar &&
                            this.state.lstChangeChart.length > 0 && (
                              <Select
                                defaultValue=""
                                className="multi-blueprint"
                                onChange={this.changeRadar}
                              >
                                <Option value="" key={-1}>
                                  Tổng quan
                                </Option>
                                {this.state.lstChangeChart.map(
                                  (testBlueprint, testBlueprintIndex) => {
                                    const check_parent =
                                      this.state.lstChangeChart.filter(
                                        (item) =>
                                          item.parent_id == testBlueprint.id,
                                      );
                                    if (check_parent.length == 0) {
                                      const parent_item =
                                        this.state.lstChangeChart.find(
                                          (item) =>
                                            item.id == testBlueprint.parent_id,
                                        );

                                      return (
                                        <Option
                                          title={
                                            parent_item
                                              ? `${parent_item.name}- ${testBlueprint.name}`
                                              : testBlueprint.name
                                          }
                                          key={testBlueprintIndex}
                                          value={testBlueprint.id}
                                        >
                                          {parent_item
                                            ? `${parent_item.name}- `
                                            : ''}
                                          {testBlueprint.name}
                                        </Option>
                                      );
                                    }

                                    return '';
                                  },
                                )}
                              </Select>
                            )}
                        </div>
                      }
                    >
                      <Radar
                        data={this.state.chartRadar}
                        options={{
                          scale: {
                            reverse: false,
                            ticks: {
                              beginAtZero: true,
                              max: 100,
                              min: 0,
                            },
                            angleLines: {
                              display: true,
                            },
                          },
                          legend: {
                            display: true,
                            position: 'bottom',
                          },
                          title: {
                            display: true,
                            text: titleRadar,
                          },
                          tooltips: {
                            // Disable the on-canvas tooltip
                            enabled: true,
                            callbacks: {
                              label(tooltipItem, data) {
                                let label =
                                  data.labels[tooltipItem.index] || '';
                                label +=
                                  ` | ${
                                    data.datasets[tooltipItem.datasetIndex]
                                      .label
                                  }` || '';
                                if (label) {
                                  label += ' | ';
                                }
                                label +=
                                  Math.round(tooltipItem.yLabel * 100) / 100;

                                return label;
                              },
                              title() {
                                return '';
                              },
                            },
                          },
                        }}
                      />
                      <p className="d-block d-sm-none text-center mt-4">
                        <i>Biểu đồ không hỗ trợ tốt trên thiết bị của bạn</i>
                      </p>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}
          {viewDetail && (
            <Row className="mb-4">
              <Col xs="12" md="12" lg="9" xl="9">
                {this.state.testBlueprints.map(
                  (testBlueprint, testBlueprintIndex) => {
                    const items = this.renderTestBlueprintDesc(testBlueprint);

                    return (
                      <div key={testBlueprintIndex}>
                        {testBlueprint.description && items > 0 && (
                          <p className="section-desc">
                            <b>{testBlueprint.description}</b>
                          </p>
                        )}

                        {this.state.testFormItems.map((item, itemIndex) => {
                          if (item.test_blueprint_id == testBlueprint.id) {
                            const childs = [];
                            if (item.is_parent) {
                              this.state.testFormChildItems.forEach(
                                (childItem) => {
                                  if (childItem.parent_id == item.id) {
                                    itemNum++;
                                    childs.push(childItem);
                                  }
                                },
                              );
                            } else {
                              itemNum++;
                            }

                            return (
                              <ViewItem
                                key={itemIndex}
                                showItemId
                                showRubricMark={this.state.mark}
                                onMarkRubricDone={this.onMarkRubricDone}
                                item={item}
                                childs={childs}
                                canEdit={false}
                                onDeleteChildItemClick={() => {}}
                                hideResponse={false}
                                showChildItemInfo
                                showInstruction={this.state.showInstruction}
                                hideItemNumber={false}
                                itemNumber={itemNum - childs.length}
                              />
                            );
                          }

                          return '';
                        })}
                      </div>
                    );
                  },
                )}
              </Col>
              <Col
                xs="12"
                md="3"
                className={`${fixed ? 'fixed-visible' : 'fixed-unvisible'}`}
              >
                <AppstoreOutlined
                  onClick={() => {
                    this.showMenuItem(!this.state.fixed);
                  }}
                  className={
                    `${scrollStatus ? 'menu-index-fixed' : ''}` +
                    ' menu-index-item-mobile primary '
                  }
                />
                <div className={`${scrollStatus ? 'menu-index-item' : ''}`}>
                  <Card className="x-card card-menu" title="Mục lục">
                    {this.state.testBlueprints.map(
                      (testBlueprint, testBlueprintIndex) => (
                        <React.Fragment key={testBlueprintIndex}>
                          {this.state.testFormItems.map((item) => {
                            if (item.test_blueprint_id == testBlueprint.id) {
                              if (item.is_parent) {
                                return this.state.testFormChildItems.map(
                                  (childItem) => {
                                    if (childItem.parent_id == item.id) {
                                      let correct = 0;
                                      if (
                                        answerTaker[`${childItem.id}`] &&
                                        answerTaker[`${childItem.id}`].data
                                      ) {
                                        if (
                                          answerTaker[`${childItem.id}`] &&
                                          answerTaker[`${childItem.id}`]
                                            .correct_answer == 0
                                        ) {
                                          correct = 2;
                                        } else {
                                          correct = 1;
                                        }
                                      }
                                      itemNumRight++;

                                      return (
                                        <div
                                          onClick={() => {
                                            this.onMenuClick(childItem.id);
                                          }}
                                          className={
                                            `${
                                              correct == 0
                                                ? ''
                                                : correct == 1
                                                ? 'correct'
                                                : 'wrong'
                                            }` +
                                            ' group-choice mr-3 mb-3 index-item'
                                          }
                                          key={childItem.id}
                                        >
                                          <span className="option-choice">
                                            {itemNumRight}
                                          </span>
                                        </div>
                                      );
                                    }

                                    return '';
                                  },
                                );
                              }
                              let correct = 0;
                              if (
                                answerTaker[`${item.id}`] &&
                                answerTaker[`${item.id}`].data
                              ) {
                                if (
                                  answerTaker[`${item.id}`] &&
                                  answerTaker[`${item.id}`].correct_answer == 0
                                ) {
                                  correct = 2;
                                } else {
                                  correct = 1;
                                }
                              }
                              itemNumRight++;

                              return (
                                <div
                                  onClick={() => {
                                    this.onMenuClick(item.id);
                                  }}
                                  className={`${
                                    correct == 0
                                      ? ''
                                      : correct == 1
                                      ? 'correct'
                                      : 'wrong'
                                  } ' group-choice mr-3 mb-3 index-item`}
                                  key={item.id}
                                >
                                  <span className="option-choice">
                                    {itemNumRight}
                                  </span>
                                </div>
                              );
                            }

                            return '';
                          })}
                        </React.Fragment>
                      ),
                    )}
                  </Card>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TestTakerDetail);
