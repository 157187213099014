import { Menu } from 'antd';
import {
  UserOutlined,
  TeamOutlined,
  QuestionCircleOutlined,
  SolutionOutlined,
  ShareAltOutlined,
  FileTextOutlined,
  AuditOutlined,
  StarOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import theme from 'themes';
import PATHS from 'themes/paths';
console.log('theme.config.features', theme.config.features);
console.log('env', process.env);
console.log(
  'x',
  process.env.REACT_APP_SHOW_XTTN,
  process.env.REACT_APP_SHOW_XTTN === '1',
  process.env.REACT_APP_SHOW_XTTN == '1',
  parseInt(process.env.REACT_APP_SHOW_XTTN),
  parseInt(process.env.REACT_APP_SHOW_XTTN) === 1,
);
const StudentMenu = ({ location }) => (
  <Menu
    theme="light"
    className="menu-sidebar"
    mode="inline"
    defaultSelectedKeys={[PATHS.classes]}
    selectedKeys={[location.pathname]}
  >
    {!theme.config.excludePaths.includes(PATHS.classes) && (
      <Menu.Item
        key={PATHS.classes}
        icon={<TeamOutlined />}
        className={
          location.pathname.includes('classes') ? 'ant-menu-item-selected' : ''
        }
      >
        <Link to={PATHS.classes}>Lớp học</Link>
      </Menu.Item>
    )}

    {!theme.config.excludePaths.includes(PATHS.testTaker) && (
      <Menu.Item
        key={PATHS.testTaker}
        icon={<SolutionOutlined />}
        className={
          location.pathname.includes(PATHS.testTaker)
            ? 'ant-menu-item-selected'
            : ''
        }
      >
        <Link to={PATHS.testTaker}>Bài thi</Link>
      </Menu.Item>
    )}

    {!theme.config.excludePaths.includes(PATHS.listExam) && (
      <Menu.Item
        key={PATHS.listExam}
        icon={<FileTextOutlined />}
        className={
          location.pathname.includes(PATHS.listExam)
            ? 'ant-menu-item-selected'
            : ''
        }
      >
        <Link to={PATHS.listExam}>Bài thi Đánh giá tư duy - TSA</Link>
      </Menu.Item>
    )}
    {!theme.config.excludePaths.includes(PATHS.xttn) &&
      parseInt(process.env.REACT_APP_SHOW_XTTN) === 1 && (
        <Menu.Item
          key={PATHS.xttn}
          icon={<StarOutlined />}
          className={
            location.pathname.includes(PATHS.xttn)
              ? 'ant-menu-item-selected'
              : ''
          }
        >
          <a href={PATHS.xttn}>Xét tuyển tài năng</a>
        </Menu.Item>
      )}
    {theme.config.features.includes(PATHS.tablePoint) && (
      <Menu.Item
        key={PATHS.tablePoint}
        icon={<AuditOutlined />}
        className={
          location.pathname.includes(PATHS.tablePoint)
            ? 'ant-menu-item-selected'
            : ''
        }
      >
        <Link to={PATHS.tablePoint}>Bảng điểm</Link>
      </Menu.Item>
    )}
    {theme.config.features.includes(PATHS.connective) && (
      <Menu.Item
        key={PATHS.connective}
        icon={<ShareAltOutlined />}
        className={
          location.pathname.includes(PATHS.connective)
            ? 'ant-menu-item-selected'
            : ''
        }
      >
        <Link to={PATHS.connective}>Đang theo dõi</Link>
      </Menu.Item>
    )}
    <Menu.Item key={PATHS.profile} icon={<UserOutlined />}>
      <Link to={PATHS.profile}>Tài khoản</Link>
    </Menu.Item>
    <Menu.Item key="4" icon={<QuestionCircleOutlined />}>
      Câu hỏi thường gặp
    </Menu.Item>
  </Menu>
);

export default StudentMenu;
