import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { connect } from 'react-redux';
import Loading from '../../../Loading';
import Classes from '../../User/Screens/Classes';
import ProfileFormInfo from '../../User/Components/ProfileFormInfo';
import theme from 'themes';
import { Redirect } from 'react-router';
import PATHS from 'themes/paths';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      openRequest: false,
      collapse: false,
      display: 1,
      visible: false,
    };
  }

  openRequest = () => {
    const { openRequest } = this.state;
    this.setState({ openRequest: !openRequest });
  };

  collapse = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === 'endDate' &&
      new Date(`${this.state.startDate._d}`) > new Date(`${date._d}`)
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (
      this.state.endDate &&
      who === 'startDate' &&
      new Date(`${this.state.endDate._d}`) < new Date(`${date._d}`)
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  toggle(tab) {
    if (this.state.activeTab != tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  changeView(tab) {
    this.setState({
      display: tab,
    });
  }

  componentDidMount() {
    if (!this.props.user) {
      message.error('Có lỗi xảy ra!');
    } else {
      this.setState(
        {
          isEdit: true,
          editUserObj: this.props.user,
        },
        async () => {
          const notifyUpdateUser =
            localStorage.getItem('notifyUpdateUser') || 0;
          if (
            (!this.props.user.fullname || !this.props.user.birthday) &&
            notifyUpdateUser == 0
          ) {
            localStorage.setItem('notifyUpdateUser', 1);
            this.showModal();
          }
        },
      );
    }
  }

  notify = () =>
    message.warning(<a href={PATHS.profile}>Hãy cập nhật thông tin hồ sơ</a>);

  render() {
    switch (theme.config.loginHomePath) {
      case PATHS.classes:
        if (this.props.user.id) {
          return (
            <>
              <Classes />
              <Modal
                title={
                  <div className="text-center mt-4 my-2">
                    <h2>Thông tin cá nhân</h2>
                    <p>
                      Hãy nhập các thông tin bên dưới, để giúp thầy cô nhận ra
                      bạn.
                    </p>
                  </div>
                }
                visible={this.state.visible}
                onOk={this.hideModal}
                onCancel={this.hideModal}
                footer={null}
                keyboard={false}
                maskClosable={false}
                closable
                centered
                className="modal-setting-info"
                closeIcon={
                  <i className="fa fa-times-circle" aria-hidden="true" />
                }
              >
                <ProfileFormInfo onCancel={this.hideModal} />
              </Modal>
            </>
          );
        }

        return <Loading />;
      case PATHS.testTaker:
        return <Redirect to={{ pathname: PATHS.testTaker }} />;
      case PATHS.listExam:
        return <Redirect to={{ pathname: PATHS.listExam }} />;
      default:
        return <>Pls check `theme.config.loginHomePath`</>;
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Dashboard);
