import PATHS from '../paths';

const theme = {
  config: {
    themeName: 'kt', // Tên trùng với folder theme
    svg: {
      color: '',
    },
    loginHomePath: PATHS.classes,
    guestHomePath: PATHS.login,
    features: [PATHS.connective],
    excludePaths: ['dk', PATHS.listExam, PATHS.reference, PATHS.xttn],
    auth: {
      register: 'basic', // full || basic
      loginRedirect: '/',
      withFriend: true, // Lấy thêm friend trong request /user/info
      registerRedirect: '/',
    },
    /* showOrderTab: false, */
    copyRightLine1: 'Khaothi.online',
    copyRightLine2: 'Copyright by FPT Information System',
  },
};

export default theme;
