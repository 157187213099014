import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PATHS from 'themes/paths';

import TsaRegister from '../Components/TsaRegister';
import { getExamRegistered } from '../service';

const RegisterExam = (props) => {
  const [loading, setLoading] = useState(false);
  const [exam, setExam] = useState(null);
  const [examPlan, setExamPlan] = useState(null);

  const { isGuest, match, history } = props;

  useEffect(() => {
    const componentDidMount = async () => {
      if (isGuest) {
        history.push(`${PATHS.login}?returnUrl=${history.location.pathname}`);

        return;
      }
      setLoading(true);

      const response = await getExamRegistered(match.params.examCode);

      if (response.data.code === 200) {
        setExam(response.data.data.exam);
        setExamPlan(response.data.data.examPlan);
      } else {
        message.error(response.data.message ? response.data.message :'Có lỗi xảy ra, vui lòng thử lại');
        history.push(PATHS.exam);

        return;
      }

      setLoading(false);
    };

    componentDidMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !exam) {
    return null;
  }

  /**
   * TODO:
   * Sau này sẽ check lại theo các kỳ thi (Có cần hồ sơ hay ko, hồ sơ lớp nào, có cần ảnh chứng mình... hay ko)
   */
  if (exam.profile_type == 'HUST') {
    return <TsaRegister {...props} exam={exam} examPlan={examPlan} />;
  }

  return <TsaRegister {...props} exam={exam} examPlan={examPlan} />;
};

const mapStateToProps = (state) => ({
  isGuest: Object.keys(state.user).length === 0,
});

export default connect(mapStateToProps)(RegisterExam);
