import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ListExamBody from '../Components/ListExamBody';
import VerifyEmailAlert from '../../User/Components/VerifyEmailAlert';
import { Button } from 'antd';
import styles from './Exam.module.less';
class ListExam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendMailStatus: 0,
      getAllExam: false
    };
  }

  render() {
    return (
      <Container>
        <Row>
          {!this.props.user.email_verified && (
            <Col xs={12} className="mb-3">
              <VerifyEmailAlert />
            </Col>
          )}
          {/* <Col span={24}>
            <div className="social__header">
              <div className="social__title">
                <div className="title-classes title-classes-light">
                  <span className="title-classes">Bài thi</span>
                </div>
                <div className="action-group-right">
                  <Button type="secondary">
                    <Link to={`${PATHS.testTaker}?type=5`}>
                      Bài thi đã đăng ký
                    </Link>
                  </Button>
                </div>
              </div>
              <hr className="line mt-3" />
            </div>
          </Col> */}
        </Row>

        <div className="social__header">
          <Row>
            <Col className="social__title" >
              <span className="title-classes title-classes-light">
                Bài thi Đánh giá tư duy - TSA
              </span>
            </Col>
            <Col sm={5} lg={4} xl={3}>
              <Button type="none" block className={styles.btn_get_all_exam} onClick={() => {
                this.setState((old_val) => ({
                  getAllExam: !old_val.getAllExam
                }))
              }}>
                  {this.state.getAllExam ? 'Các kỳ thi hoạt động' : 'Xem tất cả kỳ thi'}
              </Button>
            </Col>
          </Row>
          
          <hr className="line mt-3" />
        </div>

        <ListExamBody checkImgCccdHUST={this.props.user.check_img_cccd_hust} getAllExam={this.state.getAllExam} emailVerified={this.props.user.email_verified} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ListExam);
