/* eslint-disable newline-before-return */
import {
  BarsOutlined,
  EnvironmentOutlined,
  FormOutlined,
  IdcardOutlined,
  LoadingOutlined,
  MailOutlined,
  MobileOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Tooltip,
  Upload,
  message,
} from 'antd';
import ImgCrop from 'antd-img-crop';

import locale from 'antd/es/date-picker/locale/vi_VN';
import config from 'app/config';
import {
  filterSelectOptions,
  getErrorMessages,
  isVietnamesePhoneNumber,
} from 'app/utils/functions';
import axios from 'axios';
import { KTLoading } from 'core/ui';
import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/vi';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PATHS from 'themes/paths';
import FormHighSchool from '../Components/HighSchoolForm';
import SelectPriorityArea from '../Components/SelectPriorityArea';
import SelectPriorityObject from '../Components/SelectPriorityObject';
import countries from '../Components/countries';
import nations from '../Components/nations';
import religions from '../Components/religions';
import store from '../../../app/store';
import {
  createProfile,
  getCities,
  getDistricts,
  getSchools,
  getWards,
  processOcr,
  viewProfile,
} from '../service';
import './HustForm.less';

const dateFormatList = ['DD/MM/YYYY'];

function saveTempProfile(data) {
  // localStorage.setItem('hust_v1', JSON.stringify(data));
  // localStorage.setItem('hust_v1', `${Date.now()}`);
}

function removeTempProfile(data) {
  localStorage.removeItem('hust_v1');
  localStorage.removeItem('hust_v1');
}

function getTempProfile() {
  const strData = localStorage.getItem('hust_v1');

  try {
    const data = JSON.parse(strData);
    data.cmnd_img_before = '';
    data.cmnd_img_after = '';

    return data;
  } catch (error) {
    return {};
  }
}

// const SCAN_BACK_ID_CARD = process.env.REACT_APP_SCAN_BACK_ID_CARD || false;
//Tạm fix để không chạy vào ocr
const SCAN_BACK_ID_CARD = false;

const ref = new URLSearchParams(window.location.search).get('ref');
class HustForm extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    let profileObj = {};

    console.log('props.user', props.user);

    if (props.user) {
      if (!this.props.view) {
        profileObj = {
          email: this.props.user.email,
          //name: this.props.user.fullname,
          phone: this.props.user.phone,
        };
      }
    }

    this.state = {
      obj_type: null,
      province: {
        data: [],
        ttcn: [],
      },
      district: {
        ttcn: [],
        dctt: [],
        dcll: [],
        _10_hk1: [],
        _11_hk1: [],
        _12_hk1: [],
        _10_hk2: [],
        _11_hk2: [],
        _12_hk2: [],
      },
      ward: {
        ttcn: [],
        dctt: [],
        dcll: [],
      },
      school: {
        _10_hk1: [],
        _11_hk1: [],
        _12_hk1: [],
        _10_hk2: [],
        _11_hk2: [],
        _12_hk2: [],
      },
      profileObj,
      profileError: {},
      resetForm: false,
      upImg: false,

      cmnd_img_before: null,
      cmnd_img_after: null,
      scan_cccd_modal: false,
      scan_done: false,
      is_closable: false,
      photo: null,
    };
  }

  componentDidMount() {
    if (this.props.isGuest) {
      this.props.history.push(PATHS.login);
      return;
    }

    this.getCities(1, 'data');
    this.getCities(0, 'ttcn', true);
    if (this.props.profile) {
      this.initProfile(this.props.profile);
    } else if (this.props.id) {
      this.viewProfile(this.props.id);
    } else if (
      this.props.history.location.state &&
      this.props.history.location.state.email
    ) {
      let tempProfile = getTempProfile();
      tempProfile.email = this.props.history.location.state.email;

      tempProfile.reEmail = tempProfile.email;

      this.initProfile(tempProfile);
    } else {
      let tempProfile = getTempProfile();
      tempProfile.reEmail = '';
      this.initProfile(tempProfile);
    }
    if (!this.props.view) {
      if (!ref) {
        window.addEventListener('beforeunload', this.onUnload);
      }
    }
    if (this.props.id) {
      this.setState({ is_closable: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = (e) => {
    if (this.isEmptyProfile(this.state.profileObj)) {
      e.preventDefault();

      e.returnValue = 'Những thay đổi bạn đã thực hiện có thể không được lưu.';
    }
  };

  isEmptyProfile(profile) {
    const notEmptyKey = Object.keys(profile).find(
      (key) => !isEmpty(profile[key]),
    );

    return !!notEmptyKey;
  }

  resetField(field, value = '') {
    this.formRef.current.setFieldsValue({ [field]: value });
  }

  resetFields(fields, value = '') {
    const updateValues = {};
    fields.forEach((field) => {
      this.resetField(field, value);
      updateValues[field] = value;
    });

    const profileObj = {
      ...this.formRef.current.getFieldValue(),
      ...updateValues,
    };

    this.setState({ profileObj });
  }

  repairUpdateDate(dataInput, fields) {
    const data = dataInput;
    fields.forEach((field) => {
      try {
        const value = dataInput[field];

        data[field] = moment(value, 'DD/MM/YYYY').isValid()
          ? moment(value, 'DD/MM/YYYY').format('MM-DD-YYYY')
          : value;
      } catch (error) {
        data[field] = '';
      }
    });

    return data;
  }

  repairInitDate(dataInput, fields) {
    const data = dataInput;
    fields.forEach((field) => {
      try {
        if (dataInput[field]) {
          data[field] = moment(dataInput[field], 'MM-DD-YYYY').isValid()
            ? moment(dataInput[field], 'MM-DD-YYYY')
            : '';
        }
      } catch (error) {
        data[field] = '';
      }
    });

    return data;
  }

  initProfile = (initProfile) => {
    let profile = {
      ...(initProfile || {}),
    };

    if (profile.obj_type) {
      profile.obj_type = JSON.parse(profile.obj_type);
    }

    if (!initProfile?.cmnd) {
      this.setState({ scan_cccd_modal: true });
    }

    if (initProfile?.cmnd_img_before && initProfile?.cmnd_img_after) {
      this.setState({ scan_done: true });
    }

    this.getDistricts(1, profile.province, 'ttcn');
    this.getWards(profile.district, 'ttcn');
    this.getDistricts(1, profile.province_dctt, 'dctt');
    this.getWards(profile.district_dcll, 'dcll');
    this.getWards(profile.district_dctt, 'dctt');
    this.getDistricts(1, profile.province_dcll, 'dcll');
    this.getDistricts('', profile.province_10_hk1, '_10_hk1', true);
    this.getDistricts('', profile.province_10_hk2, '_10_hk2', true);
    this.getDistricts('', profile.province_11_hk1, '_11_hk1', true);
    this.getDistricts('', profile.province_11_hk2, '_11_hk2', true);
    this.getDistricts('', profile.province_12_hk1, '_12_hk1', true);
    this.getDistricts('', profile.province_12_hk2, '_12_hk2', true);
    this.getSchools(
      profile.province_10_hk1,
      profile.district_10_hk1,
      '_10_hk1',
    );
    this.getSchools(
      profile.province_10_hk2,
      profile.district_10_hk2,
      '_10_hk2',
    );
    this.getSchools(
      profile.province_11_hk1,
      profile.district_11_hk1,
      '_11_hk1',
    );
    this.getSchools(
      profile.province_11_hk2,
      profile.district_11_hk2,
      '_11_hk2',
    );
    this.getSchools(
      profile.province_12_hk1,
      profile.district_12_hk1,
      '_12_hk1',
    );
    this.getSchools(
      profile.province_12_hk2,
      profile.district_12_hk2,
      '_12_hk2',
    );

    profile = this.repairInitDate(profile, ['birthday', 'createDateCmnd']);

    console.log('repairInitDate', profile);

    this.setState({
      profileObj: profile,
      obj_type: profile?.obj_type,
      imageUrlPhoto: profile.photo,
      cmnd_img_after: profile.cmnd_img_after,
      cmnd_img_before: profile.cmnd_img_before,
    });

    this.formRef.current.setFieldsValue(profile);
  };

  viewProfile = async (id) => {
    const response = await viewProfile(id);
    if (response.data.code == 200) {
      let profile = response.data.data.profiles;
      if (profile.code != 'HUST') {
        message.error(
          'Địa chỉ truy cập không đúng. Hệ thống đang chuyển hướng',
        );

        setTimeout(function () {
          try {
            window.location = this.getReturnUrl();
          } catch (error) {}
        }, 2000);

        return;
      }
      if (profile.data) {
        profile = profile.data;
        this.initProfile(profile);
      }
    } else {
      message.error(getErrorMessages(response.data));

      setTimeout(function () {
        try {
          window.location = this.getReturnUrl();
        } catch (error) {}
      }, 2000);

      return;
    }
  };

  getReturnUrl() {
    if (this.props.location && this.props.location.returnUrl) {
      return this.props.location.returnUrl;
    }

    return this.props.returnUrl || PATHS.profile;
  }

  getCities = async (type = '', child, allowOther = false) => {
    const response = await getCities(type);
    if (response.data.code == 200) {
      const cities = response.data.data.cities.map((i) => ({
        value: i.code || i.provinceid,
        label: i.name,
      }));

      this.setState({
        province: {
          ...this.state.province,
          [child]: !allowOther
            ? cities
            : [...cities, { value: '-1', label: 'Khác' }],
        },
      });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  getDistricts = async (type = '', id, child, allowOther = false) => {
    const response = await getDistricts(type, id);
    if (response.data.code == 200) {
      const districts = response.data.data.districts.map((i) => ({
        value: i.code || i.districtid,
        label: i.name,
      }));

      this.setState({
        district: {
          ...this.state.district,
          [child]: !allowOther
            ? districts
            : [...districts, { value: '-1', label: 'Khác' }],
        },
      });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  getWards = async (id, child) => {
    const response = await getWards(id);
    if (response.data.code == 200) {
      const wards = response.data.data.wards.map((i) => ({
        value: i.code || i.wardid,
        label: i.name,
      }));

      this.setState({
        ward: { ...this.state.ward, [child]: wards },
      });
    }
  };

  getSchools = async (city, district, type) => {
    const response = await getSchools(city, district);
    if (response.data.code == 200) {
      const schools = response.data.data.schools.map((i) => ({
        value: i.code,
        label: i.name,
      }));

      this.setState({
        school: {
          ...this.state.school,
          [type]: [...schools, { value: '-1', label: 'Khác' }],
        },
      });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  onValuesChange = (changedValues, allValues) => {
    if (changedValues.province) {
      this.getDistricts(1, changedValues.province, 'ttcn');
    }
    if (changedValues.district) {
      this.getWards(changedValues.district, 'ttcn');
    }
    if (changedValues.province_dctt) {
      this.getDistricts(1, changedValues.province_dctt, 'dctt');
    }
    if (changedValues.district_dcll) {
      this.getWards(changedValues.district_dcll, 'dcll');
    }
    if (changedValues.district_dctt) {
      this.getWards(changedValues.district_dctt, 'dctt');
    }
    if (changedValues.province_dcll) {
      this.getDistricts(1, changedValues.province_dcll, 'dcll');
    }
    if (changedValues.province_10_hk1) {
      this.getDistricts('', changedValues.province_10_hk1, '_10_hk1', true);
    }
    if (changedValues.province_11_hk1) {
      this.getDistricts('', changedValues.province_11_hk1, '_11_hk1', true);
    }
    if (changedValues.province_12_hk1) {
      this.getDistricts('', changedValues.province_12_hk1, '_12_hk1', true);
    }
    if (changedValues.province_10_hk2) {
      this.getDistricts('', changedValues.province_10_hk2, '_10_hk2', true);
    }
    if (changedValues.province_11_hk2) {
      this.getDistricts('', changedValues.province_11_hk2, '_11_hk2', true);
    }
    if (changedValues.province_12_hk2) {
      this.getDistricts('', changedValues.province_12_hk2, '_12_hk2', true);
    }
    if (changedValues.district_10_hk1) {
      this.getSchools(
        this.state.profileObj.province_10_hk1,
        changedValues.district_10_hk1,
        '_10_hk1',
      );
    }
    if (changedValues.district_11_hk1) {
      this.getSchools(
        this.state.profileObj.province_11_hk1,
        changedValues.district_11_hk1,
        '_11_hk1',
      );
    }
    if (changedValues.district_12_hk1) {
      this.getSchools(
        this.state.profileObj.province_12_hk1,
        changedValues.district_12_hk1,
        '_12_hk1',
      );
    }
    if (changedValues.district_10_hk2) {
      this.getSchools(
        this.state.profileObj.province_10_hk2,
        changedValues.district_10_hk2,
        '_10_hk2',
      );
    }
    if (changedValues.district_11_hk2) {
      this.getSchools(
        this.state.profileObj.province_11_hk2,
        changedValues.district_11_hk2,
        '_11_hk2',
      );
    }
    if (changedValues.district_12_hk2) {
      this.getSchools(
        this.state.profileObj.province_12_hk2,
        changedValues.district_12_hk2,
        '_12_hk2',
      );
    }

    this.setState({
      profileObj: allValues,
    });

    saveTempProfile(allValues);
  };

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        return resolve(
          reader.result.replace(/^data:image\/[a-z]+;base64,/, ''),
        );
      };
      reader.readAsDataURL(blob);
    });
  }

  processCmt = (options, type) => {
    const imageFile = options.file;
    const obj = { cccd_type: type };

    if (imageFile) {
      if (
        !imageFile.type ||
        !['image/jpeg', 'image/jpg', 'image/png'].includes(
          imageFile.type.toLowerCase(),
        )
      ) {
        message.error('Vui lòng chọn ảnh PNG hoặc JPG');
        this.resetField(`cmnd_img_${type}`);
        this.setState({
          ...obj,
          profileObj: {
            ...this.state.profileObj,
            [`cmnd_img_${type}`]: null,
            statusPhoto: 'error',
          },
        });
        return;
      }
      const isLt2M = imageFile.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Vui lòng chọn ảnh không quá 2MB!');
        this.resetField(`cmnd_img_${type}`);
        this.setState({
          ...obj,
          profileObj: { ...this.state.profileObj, [`cmnd_img_${type}`]: null },
          statusPhoto: 'error',
        });
      } else {
        this.setState({
          ...obj,
          [`cmnd_img_${type}`]: URL.createObjectURL(imageFile),
          [`cmnd_img_${type}_file`]: imageFile,
        });
      }
    }
  };

  async processOCR(prams, type) {
    this.setState({ showModalLoading: true });
    const response = await processOcr(prams);
    if (response.data.code == 200) {
      if (
        response.data.data.kquaXThuc &&
        response.data.data.kquaXThuc.maKQua == 200 &&
        !response.data.data.kquaXThuc.maMTa
      ) {
        const data = response.data.data.gtoTThan;
        const profileObj = {
          ...this.state.profileObj,
        };

        if (data?.loaiGToTThan == 1) {
          this.clearProcess(type);
          return message.error(
            'Không nhận dạng được CCCD trong ảnh. Vui lòng tải lên ảnh CCCD rõ ràng hơn. Không sử dụng Chứng minh nhân dân cũ.',
          );
        }

        if (prams.is_after == 1) {
          if (data.ngayCapScore < 0.85) {
            this.clearProcess(type);

            return message.error(
              'Ngày cấp không rõ ràng. Vui lòng tải lên ảnh CCCD mới.',
            );
          } else {
            profileObj.createDateCmnd = moment(data.ngayCap2, 'DD/MM/YYYY');
          }
        } else {
          if (data.soCmtScore <= 0.85) {
            this.clearProcess(type);

            return message.error(
              'Số căn cước công dân không rõ ràng. Vui lòng tải lên ảnh CCCD mới.',
            );
          } else {
            profileObj.cmnd = data.soCmt;
            profileObj.recmnd = data.soCmt;
          }

          if (data.hoVaTenScore <= 0.85) {
            this.clearProcess(type);

            return message.error(
              'Họ và tên không rõ ràng. Vui lòng tải lên ảnh CCCD mới.',
            );
          } else {
            profileObj.name = data.hoVaTen;
          }

          if (data.namSinhScore <= 0.85) {
            this.clearProcess(type);

            return message.error(
              'Ngày tháng năm sinh không rõ ràng. Vui lòng tải lên ảnh CCCD mới.',
            );
          } else {
            profileObj.birthday = moment(data.namSinh, 'DD/MM/YYYY');
          }

          if (data.quocTichScore <= 0.85) {
            this.clearProcess(type);

            return message.error(
              'Quốc tịch không rõ ràng. Vui lòng tải lên ảnh CCCD mới.',
            );
          } else {
            const country = countries.find(
              (c) =>
                c.label.toLocaleUpperCase() ==
                data.quocTich.toLocaleUpperCase(),
            );
            if (country) {
              profileObj.nationality = country.value;
            }
          }
        }

        if (data.gioiTinhScore < 0.85) {
          this.clearProcess();

          return message.error(
            'Giới tính không rõ ràng. Vui lòng tải lên ảnh CCCD mới.',
          );
        } else {
          profileObj.gender = data.gioiTinh2 == 'NAM' ? '1' : '2';
        }

        this.setState({ showModalLoading: false });
        if (prams.is_after == 1) {
          this.setState({
            scan_done: true,
          });
          message.success('Xác thực mặt sau căn cước công dân thành công.');
        } else {
          if (this.state.cmnd_img_after_file) {
            this.setState({
              scan_done: true,
            });
          }
          if (this.props.id) {
            this.setState({ is_closable: false });
          }
          message.success('Xác thực mặt trước căn cước công dân thành công.');
        }

        this.setState({
          profileObj,
          // scan_cccd_modal: false,
        });

        this.formRef.current.setFieldsValue({ ...profileObj });
      } else {
        this.clearProcess(type);
        message.error(
          'Quá trình xác thực không thành công. Vui lòng sử dụng ảnh CCCD khác rõ hơn để tiếp tục.',
        );
      }
    } else {
      this.clearProcess(type);
      message.error(
        'Quá trình xác thực không thành công. Vui lòng sử dụng ảnh CCCD khác rõ hơn để tiếp tục.',
      );
    }
  }

  async clearProcess(type) {
    this.setState({ showModalLoading: false });
    this.setState({
      scan_done: false,
    });
    return;
    this.setState({ showModalLoading: false });
    this.resetField(`cmnd_img_${type}`);
    this.setState({
      profileObj: { ...this.state.profileObj, [`cmnd_img_${type}`]: null },
      [`cmnd_img_${type}`]: null,
      [`cmnd_img_${type}_file`]: null,
      scan_done: false,
    });
  }

  async reScanOCR() {
    this.setState(
      {
        cmnd_img_before_tmp: this.state.profileObj.cmnd_img_before,
        cmnd_img_after_tmp: this.state.profileObj.cmnd_img_after,
        cmnd_img_before_file_tmp: this.state.cmnd_img_before_file || '',
        cmnd_img_after_file_tmp: this.state.cmnd_img_after_file || '',
      },
      async () => {
        this.resetField('cmnd_img_before');
        this.resetField('cmnd_img_after');
        this.setState({
          profileObj: {
            ...this.state.profileObj,
            cmnd_img_before: '',
            cmnd_img_after: '',
          },
          cmnd_img_before: '',
          cmnd_img_after: '',
          cmnd_img_before_file: '',
          cmnd_img_after_file: '',
          scan_done: false,
          is_closable: true,
        });
      },
    );
    this.setState({ scan_cccd_modal: true });
  }

  async onCloseOcrModal() {
    if (this.state.is_closable === true) {
      this.setState({ scan_cccd_modal: false });
      this.setState({
        profileObj: {
          ...this.state.profileObj,
          cmnd_img_before: this.state.cmnd_img_before_tmp,
          cmnd_img_after: this.state.cmnd_img_after_tmp,
        },
        cmnd_img_before: this.state.cmnd_img_before_file_tmp
          ? URL.createObjectURL(this.state.cmnd_img_before_file_tmp)
          : null,
        cmnd_img_after: this.state.cmnd_img_after_file_tmp
          ? URL.createObjectURL(this.state.cmnd_img_after_file_tmp)
          : null,
        cmnd_img_before_file: this.state.cmnd_img_before_file_tmp,
        cmnd_img_after_file: this.state.cmnd_img_after_file_tmp,
      });
    }
  }

  async getOrcTransaction() {
    const configData = {
      method: 'get',
      url: `${config.getApiDomainOrc()}/api/public/all/ma-giao-dich`,
      headers: {
        Token: 'd57c5b17-3d93-4755-a9b1-b2beb84454ef',
        Code: 'KHAOTHI',
        'Accept-Language': 'vi',
      },
    };

    const result = await axios(configData);

    if (result.data.status === 200) {
      return result.data.data;
    }

    throw Error('Có lỗi xảy ra vui lòng thử lại');
  }

  async processOrc(anhMatTruoc, anhMatSau) {
    this.setState({ showModalLoading: true });
    try {
      const transaction = await this.getOrcTransaction();

      const axios = require('axios');
      var data = JSON.stringify({
        anhMatTruoc,
        anhMatSau,
        maGiayTo: 'cmtnd',
      });

      const configData = {
        method: 'post',
        url: `${config.getApiDomainOrc()}/api/public/all/doc-noi-dung-ocr`,
        headers: {
          Token: 'd57c5b17-3d93-4755-a9b1-b2beb84454ef',
          Code: 'KHAOTHI',
          'Accept-Language': 'vi',
          code_transaction: transaction,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      const result = await axios(configData);

      console.log(result, 'result');

      if (result.data.status === 200) {
        const data = result.data.data;
        const profileObj = {
          ...this.state.profileObj,
          // basic_nationality: data.danToc,
          // basic_religion: data.tonGiao,
        };

        if (data.hoVaTenScore > 0.9) {
          profileObj.name = data.hoVaTen;
        }

        if (data.namSinhScore > 0.9) {
          profileObj.birthday = moment(data.namSinh, 'DD/MM/YYYY');
        }

        if (data.soCmtScore > 0.9) {
          profileObj.cmnd = data.soCmt;
          profileObj.recmnd = data.soCmt;
        }

        if (data.danTocScore > 0.9) {
          const nation = nations.find(
            (n) => n.label.toLocaleUpperCase() == data.danToc,
          );
          if (nation) {
            profileObj.nation = nation.value;
          }
        }

        if (data.tonGiaoScore > 0.9) {
          const religion = religions.find(
            (r) => r.label.toLocaleUpperCase() == data.tonGiao,
          );
          if (religion) {
            profileObj.religion = religion.value;
          }
        }

        if (data.quocTichScore > 0.9) {
          const country = countries.find(
            (c) =>
              c.label.toLocaleUpperCase() == data.quocTich.toLocaleUpperCase(),
          );
          if (country) {
            profileObj.nationality = country.value;
          }
        }

        if (
          data.noiCapScore > 0.9 &&
          this.state.province &&
          this.state.province.data
        ) {
          const province = this.state.province.data.find(
            (p) =>
              p.label.toLocaleUpperCase() == data.noiCap.toLocaleUpperCase(),
          );
          if (province) {
            profileObj.createAddCmnd = province.value;
          }
        }

        this.setState({
          profileObj,
        });

        this.formRef.current.setFieldsValue({ ...profileObj });
      } else {
        message.error(result.data.message);
      }
    } catch (error) {}
    this.setState({ showModalLoading: false });
  }

  processPhoto = (options) => {
    const imageFile = options.file;
    if (imageFile) {
      if (
        !imageFile.type ||
        !['image/jpeg', 'image/jpg', 'image/png'].includes(
          imageFile.type.toLowerCase(),
        )
      ) {
        message.error('Vui lòng chọn ảnh PNG hoặc JPG');
        this.resetField('photo');
        this.setState({
          profileObj: { ...this.state.profileObj, photo: null },
        });

        return;
      }

      const isLt2M = imageFile.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error('Vui lòng chọn ảnh không quá 2MB!');
        this.resetField('photo');
        this.setState({
          profileObj: { ...this.state.profileObj, photo: null },
        });

        return;
      } else {
        this.setState({
          photo: URL.createObjectURL(imageFile),
          photo_file: imageFile,
        });
      }
    }
  };

  setDcllAsDctt() {
    const { profileObj } = this.state;

    profileObj.province_dcll = profileObj.province_dctt;
    profileObj.district_dcll = profileObj.district_dctt;
    profileObj.ward_dcll = profileObj.ward_dctt;
    profileObj.address_dcll = profileObj.address_dctt;

    const { district } = this.state;
    district.dcll = district.dctt;
    const { ward } = this.state;
    ward.dcll = ward.dctt;

    this.setState({ profileObj, district, ward });

    this.formRef.current.setFieldsValue({ ...profileObj });
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  repairString(dataInput, fields) {
    const data = dataInput;
    fields.forEach((field) => {
      data[field] = typeof data[field] == 'string' ? data[field].trim() : '';
    });

    return data;
  }

  onFinish = async (_values) => {
    if (this.state.saved) {
      return;
    }

    if(!this.state.cmnd_img_before){
      message.error('Bạn cần tải lên căn cước mặt trước');
      this.formRef.current.setFields([
        {
          name: 'cmnd_img_before',
          errors: ['Vui lòng tải lên căn cước mặt trước'],
        }
      ]);
      return
     }
    if(!this.state.cmnd_img_after){
      message.error('Bạn cần tải lên căn cước mặt sau');
       this.formRef.current.setFields([
        {
          name: 'cmnd_img_after',
          errors: ['Vui lòng tải lên căn cước mặt sau'],
        },
      ]);
      return
     }
    
    saveTempProfile(_values);

    this.setState({
      loading: true,
    });

    const data = {
      name: 'Hồ sơ dự thi',
      code: 'HUST',
      data: {
        ...this.state.profileObj,
        photo: this.state.imageUrlPhoto,
        cmnd_img_after: this.state.cmnd_img_after,
        cmnd_img_before: this.state.cmnd_img_before,
        birthday: this.state.profileObj.birthday,
        createDateCmnd: this.state.profileObj.createDateCmnd,
      },
    };

    data.data = this.repairString(data.data, [
      'name',
      'cmnd',
      'recmnd',
      'address_dctt',
    ]);

    data.data = this.repairUpdateDate(data.data, [
      'birthday',
      'createDateCmnd',
    ]);

    if (this.props.id) {
      data.id = this.props.id;
    }

    if (this.state.cmnd_img_before_file) {
      data.cmnd_img_before_file = this.state.cmnd_img_before_file;
    }

    if (this.state.cmnd_img_after_file) {
      data.cmnd_img_after_file = this.state.cmnd_img_after_file;
    }

    if (this.state.photo_file) {
      data.photo_file = this.state.photo_file;
    }

    const response = await createProfile(data);

    const self = this;

    if (response.data.code == 200) {
      removeTempProfile();
      this.setState(
        {
          loading: false,
          saved: true,
        },
        () => {
          message.success(response.data.message);
          setTimeout(() => {
            let state = {};
            try {
              state = self.props.location.state || {};
              const profile = response.data.data.profile;
              state.list_profile = [profile];
              state.chooseProfile = profile;
              //check update status img cccd
              // profile.data.cmnd_img_after cmnd_img_before
              let check_img_cccd_hust = null;
              let cloneUser = { ...this.props.user }
              if(!profile.data.cmnd_img_after || !profile.data.cmnd_img_before) {
                check_img_cccd_hust = profile.id
              }
              cloneUser.check_img_cccd_hust = check_img_cccd_hust
              store.dispatch({ type: 'UPDATE_USER', user: cloneUser });
            } catch (error) {
              console.log(error);
            }
            if (ref) {
              window.location.assign(`${process.env.REACT_APP_DOMAIN}xttn`);
              // window.location.assign('http://localhost:3000/xttn');
              return;
            }
            self.props.history.push(self.getReturnUrl(), state);
          }, 2000);
        },
      );
    } else {
      this.setState(
        {
          loading: false,
        },
        () => {
          if (
            typeof response.data.errors != 'undefined' &&
            Object.keys(response.data.errors).length > 0
          ) {
            Object.values(response.data.errors).forEach((element) => {
              message.error(element);
            });
          } else {
            message.error(getErrorMessages(response.data));
          }
        },
      );
    }
  };

  setThptAs(fromPrefix, toPrefix) {
    const profileObj = {
      ...this.state.profileObj,
      ...this.formRef.current.getFieldValue(),
    };

    profileObj[`province${toPrefix}`] = profileObj[`province${fromPrefix}`];
    profileObj[`province${toPrefix}_other`] =
      profileObj[`province${fromPrefix}_other`];

    profileObj[`district${toPrefix}`] = profileObj[`district${fromPrefix}`];
    profileObj[`district${toPrefix}_other`] =
      profileObj[`district${fromPrefix}_other`];

    profileObj[`highschool${toPrefix}`] = profileObj[`highschool${fromPrefix}`];
    profileObj[`highschool${toPrefix}_other`] =
      profileObj[`highschool${fromPrefix}_other`];

    const { district } = this.state;
    district[toPrefix] = district[fromPrefix];
    const { school } = this.state;
    school[toPrefix] = school[fromPrefix];

    this.setState({ profileObj, district, school });

    this.formRef.current.setFieldsValue({ ...profileObj });
  }

  onFinishFailed = (_errorInfo) => {
    message.error('Bạn cần nhập đủ thông tin');
  };

  onChangeDatePicker = (_date, dateString, type) => {
    const { profileObj } = this.state;
    profileObj[type] = dateString;
    this.setState({
      profileObj,
    });
  };

  handleModalCropOk = async () => {
    setTimeout(async () => {
      const type = this.state.cccd_type;
      const status = this.state.statusPhoto;
      if (status && status === 'error' && type == 'before') {
        this.setState({
          cmnd_img_before: null,
          statusPhoto: null,
        });
        return;
      }
      if (status && status === 'error' && type == 'after') {
        this.setState({
          cmnd_img_after: null,
          statusPhoto: null,
        });
        return;
      }
      if (status && status === 'error' && type == 'photo') {
        this.setState({ cmnd_img_after: null, statusPhoto: null });
        return;
      }

      if (type == 'before' && this.state.cmnd_img_before_file) {
        const anhMatTruoc = await this.blobToBase64(
          this.state.cmnd_img_before_file,
        );
        this.processOCR({ cccd_img_before: anhMatTruoc }, type);
        return;
      }
      if (type == 'after' && this.state.cmnd_img_after_file) {
        const anhMatSau = await this.blobToBase64(
          this.state.cmnd_img_after_file,
        );
        if (SCAN_BACK_ID_CARD) {
          this.processOCR({ cccd_img_after: anhMatSau, is_after: 1 }, type);
        } else {
          this.setState({
            scan_done: true,
          });
          message.success('Tải lên mặt sau căn cước công dân thành công.');
        }
      }
    }, 1500);
  };

  render() {
    const { school, ward, district, province, profileObj, upImg } = this.state;
    // console.log('profileObj', profileObj);
    // console.log('zzzz', moment().add(-18, 'year'));
    return (
      <>
        <Modal
          visible={this.state.scan_cccd_modal}
          title="Tải lên ảnh căn cước công dân"
          width={700}
          // closable={this.state.is_closable}
          closable={this.state.is_closable}
          onCancel={() => this.onCloseOcrModal()}
          footer={[
            this.state.scan_done && (
              <>
                Tiếp tục và kiểm tra kỹ thông tin cá nhân được tự động nhận diện
                &nbsp; &nbsp;
                <Button
                  key="next"
                  type="primary"
                  onClick={() => this.setState({ scan_cccd_modal: false })}
                >
                  Tiếp tục
                </Button>
              </>
            ),
          ]}
        >
          <Form layout="vertical" name="basic">
            <Row>
              <Col xs={24} md={24}>
                <Alert
                  className="alert-info-px"
                  message=""
                  description={
                    <>
                      Lưu ý: Ảnh chụp CCCD phải rõ ràng, đầy đủ thông tin, không
                      bị cắt, bị mờ, bị tối hoặc lóa sáng. Chỉ chụp riêng CCCD
                      không để lẫn các đồ vật khác vào ảnh. <br />
                      Ảnh phải có dung lương nhỏ hơn 2MB.
                    </>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Row
              gutter={16}
              justify="space-around"
              align="middle"
              className="mt-3"
            >
              <Col xs={24} md={12}>
                <Form.Item
                  label="Mặt trước CCCD"
                  name="cmnd_img_before"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng tải lên mặt trước',
                    },
                  ]}
                >
                  <ImgCrop
                    rotationSlider
                    modalTitle="Sửa ảnh CCCD mặt trước"
                    aspect={16 / 9}
                    onModalOk={() => this.handleModalCropOk('before')}
                  >
                    <Upload
                      // disabled={!!this.props.view}
                      name="cmnd_img_before"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      // onChange={(info) => this.processCmt(info, 'before')}
                      customRequest={(info) => {
                        // Plz don't remove empty function.
                        this.processCmt(info, 'before');
                      }}
                      accept=".png, .jpg, .jpeg"
                    >
                      {this.state.cmnd_img_before ||
                      this.state.profileObj.cmnd_img_before ? (
                        <img
                          src={
                            this.state.cmnd_img_before ||
                            this.state.profileObj.cmnd_img_before
                          }
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <div>
                          {upImg === 'before' ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                          <div style={{ marginTop: 8 }}>Tải lên</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Mặt sau CCCD"
                  name="cmnd_img_after"
                  rules={[
                    { required: true, message: 'Vui lòng tải lên mặt sau' },
                  ]}
                >
                  <ImgCrop
                    rotationSlider
                    modalTitle="Sửa ảnh CCCD mặt sau"
                    aspect={16 / 9}
                    onModalOk={() => this.handleModalCropOk()}
                  >
                    <Upload
                      disabled={this.state.cmnd_img_before ? false : true}
                      name="cmnd_img_after"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      // onChange={(info) => this.processCmt(info, 'after')}
                      accept=".png, .jpg, .jpeg"
                      customRequest={(info) => {
                        // Plz don't remove empty function.
                        this.processCmt(info, 'after');
                      }}
                    >
                      {this.state.cmnd_img_after ||
                      this.state.profileObj.cmnd_img_after ? (
                        <img
                          src={
                            this.state.cmnd_img_after ||
                            this.state.profileObj.cmnd_img_after
                          }
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <div>
                          {upImg === 'after' ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                          <div style={{ marginTop: 8 }}>Tải lên</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          visible={this.state.showModalLoading}
          title={
            <Row justify="center">
              <b>Đang xử lý thông tin</b>
            </Row>
          }
          footer={null}
          closable={false}
        >
          <Row justify="center">
            <KTLoading loadingText="Vui lòng đợi trong giây lát" />
          </Row>
        </Modal>

        <Form
          layout="vertical"
          name="basic"
          className={`profile-form ${
            this.props.view ? 'profile-form-view' : ''
          }`}
          initialValues={profileObj}
          onValuesChange={this.onValuesChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
          autoComplete="off"
        >
          <div className="legend">Thông tin cá nhân hồ sơ</div>
          <div className="section-vnu">
            <Row gutter={16} justify="center" align="middle">
              <Form.Item
                label="Ảnh thẻ (3x4)"
                name="photo"
                rules={[
                  {
                    required: true,
                    // message: 'Vui lòng tải lên Ảnh thẻ (3x4)',
                    validator: async (_, value) => {
                      if (!this.state.photo && !this.state.profileObj.photo) {
                        throw new Error('Vui lòng tải lên Ảnh thẻ (3x4)');
                      }
                    },
                  },
                ]}
              >
                <ImgCrop
                  modalTitle="Sửa ảnh"
                  rotationSlider
                  aspect={3 / 4}
                  modalProps={{ className: 'crop-photo' }}
                >
                  <Upload
                    disabled={!!this.props.view}
                    name="photo"
                    listType="picture-card"
                    className="photo-uploader"
                    showUploadList={false}
                    // fileList={[]}
                    customRequest={(info) => {
                      // Plz don't remove empty function.
                      this.processPhoto(info);
                    }}
                    accept=".png, .jpg, .jpeg"
                    // customRequest={(info) => {
                    //   // Plz don't remove empty function.
                    // }}
                  >
                    {this.state.photo || this.state.profileObj.photo ? (
                      <img
                        src={this.state.photo || this.state.profileObj.photo}
                        alt="avatar"
                        style={{ maxWidth: '100%' }}
                      />
                    ) : (
                      <div>
                        {upImg === 'before' ? (
                          <LoadingOutlined />
                        ) : (
                          <PlusOutlined />
                        )}
                        <div style={{ marginTop: 8 }}>Tải lên</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Col xs={24} md={16}>
                <Alert
                  className="alert-info-px"
                  message="Lưu ý:"
                  description={
                    <>
                      - Ảnh chân dung chụp dọc, thẳng mặt từ vai trở lên trên
                      nền đơn sắc (tương tự ảnh thẻ 3x4), nghiêm túc, lịch sự,
                      màu rõ ràng, sắc nét.
                      <br /> - Sẽ dùng làm ảnh thẻ dự thi, cấp chứng chỉ và kiểm
                      tra khi trúng tuyển.
                    </>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>

            <Row gutter={16} justify="space-around" align="middle">
              <Col xs={24} md={12}>
                <Form.Item
                  label="Mặt trước CCCD"
                  name="cmnd_img_before"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Vui lòng tải lên mặt trước',
                  //   },
                  // ]}
                  extra={
                    !this.props.view && this.props.id ? (
                      <Tooltip placement="top" title="Sửa ảnh CCCD">
                        <FormOutlined
                          onClick={() => this.reScanOCR()}
                          className="f-s-md vertical-baseline primary cursor"
                        />
                      </Tooltip>
                    ) : (
                      ''
                    )
                  }
                >
                  <ImgCrop aspect={16 / 9}>
                    <Upload
                      disabled={true}
                      name="cmnd_img_before"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      fileList={[]}
                      // onChange={() => this.setState({ scan_cccd_modal: true })}
                      accept=".png, .jpg, .jpeg"
                      customRequest={(info) => {
                        // Plz don't remove empty function.
                      }}
                    >
                      {this.state.cmnd_img_before ||
                      this.state.profileObj.cmnd_img_before ? (
                        <img
                          src={
                            this.state.cmnd_img_before ||
                            this.state.profileObj.cmnd_img_before
                          }
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <div>
                          {upImg === 'before' ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                          <div style={{ marginTop: 8 }}>Tải lên</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Mặt sau CCCD"
                  name="cmnd_img_after"
                  // rules={[
                  //   { required: true, message: 'Vui lòng tải lên mặt sau' },
                  // ]}
                  extra={
                    !this.props.view && this.props.id ? (
                      <Tooltip placement="top" title="Sửa ảnh CCCD">
                        <FormOutlined
                          onClick={() => this.reScanOCR()}
                          className="f-s-md vertical-baseline primary cursor"
                        />
                      </Tooltip>
                    ) : (
                      ''
                    )
                  }
                >
                  <ImgCrop aspect={16 / 9}>
                    <Upload
                      disabled={true}
                      name="cmnd_img_after"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      fileList={[]}
                      // onChange={(info) => this.processCmt(info, 'after')}
                      accept=".png, .jpg, .jpeg"
                      customRequest={(info) => {
                        // Plz don't remove empty function.
                      }}
                    >
                      {this.state.cmnd_img_after ||
                      this.state.profileObj.cmnd_img_after ? (
                        <img
                          src={
                            this.state.cmnd_img_after ||
                            this.state.profileObj.cmnd_img_after
                          }
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <div>
                          {upImg === 'after' ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                          <div style={{ marginTop: 8 }}>Tải lên</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>
              {/* </Row> */}
              {/* </Col> */}
              {/* <Col xs={24} md={8}>
                <Alert
                  className="alert-info-px"
                  message="Lưu ý:"
                  description={
                    <>
                      Hình CMND/CCCD/Hộ chiếu tải lên có kích thước ≥350px và
                      nhỏ hơn 2MB.
                    </>
                  }
                  type="info"
                  showIcon
                />
              </Col> */}
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Họ và Tên (Theo CMND/CCCD)</span>}
                  name="name"
                  normalize={(value) => {
                    if (value.trim() === '') {
                      return '';
                    }

                    return value.toLocaleUpperCase();
                  }}
                  rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}
                >
                  <Input
                    disabled={!!this.props.view}
                    tabIndex={1}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Giới tính</span>}
                  name="gender"
                  rules={[
                    { required: true, message: 'Vui lòng chọn giới tính' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    showSearch
                    options={[
                      { value: '1', label: 'Nam' },
                      { value: '2', label: 'Nữ' },
                    ]}
                    placeholder="Chọn"
                    notFoundContent="Không tìm thấy kết quả"
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={
                    <span>
                      Ngày/tháng/năm sinh (ví dụ 20/12/
                      {new Date().getFullYear() - 17})
                    </span>
                  }
                  name="birthday"
                  rules={[
                    { required: true, message: 'Vui lòng chọn ngày sinh' },
                  ]}
                >
                  <DatePicker
                    disabled={!!this.props.view}
                    defaultPickerValue={
                      profileObj.birthday
                        ? profileObj.birthday
                        : moment().add(-18, 'year')
                    }
                    disabledDate={(current) =>
                      moment().add(-100, 'year') >= current ||
                      moment().add(-5, 'year') <= current
                    }
                    format={dateFormatList}
                    locale={locale}
                    onChange={(date, dateString) =>
                      this.onChangeDatePicker(date, dateString, 'birthday')
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Tỉnh/TP nơi sinh</span>}
                  name="province"
                  rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/TP' }]}
                >
                  <Select
                    disabled={!!this.props.view}
                    showSearch
                    placeholder="Chọn"
                    notFoundContent="Không tìm thấy kết quả"
                    options={province.data}
                    onChange={(_) => this.resetFields(['district', 'ward'])}
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Quận/Huyện nơi sinh</span>}
                  name="district"
                  rules={[
                    { required: true, message: 'Vui lòng chọn Quận/Huyện' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    showSearch
                    placeholder="Chọn"
                    notFoundContent="Không tìm thấy kết quả"
                    onChange={(_) => this.resetField('ward')}
                    options={district.ttcn}
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Phường/Xã nơi sinh</span>}
                  name="ward"
                  rules={[
                    { required: true, message: 'Vui lòng chọn Phường/Xã' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    showSearch
                    placeholder="Chọn"
                    options={ward.ttcn}
                    notFoundContent="Không tìm thấy kết quả"
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="section-vnu section-vnu-le">
            <Row gutter={16}>
              <Col xs={24} md={16}>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span>Số Căn cước công dân/CMND/Hộ chiếu</span>}
                      name="cmnd"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập CCCD/CMND/HC',
                        },
                      ]}
                    >
                      <Input
                        disabled={!!this.props.view}
                        prefix={
                          <IdcardOutlined className="site-form-item-icon" />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={
                        <span>
                          Ngày cấp (ví dụ 25/08/
                          {new Date().getFullYear() - 1})
                        </span>
                      }
                      name="createDateCmnd"
                      rules={[
                        { required: true, message: 'Vui lòng chọn ngày cấp' },
                      ]}
                    >
                      <DatePicker
                        disabled={!!this.props.view}
                        defaultPickerValue={
                          profileObj.createDateCmnd
                            ? profileObj.createDateCmnd
                            : moment()
                        }
                        disabledDate={(current) =>
                          moment().add(-100, 'year') >= current ||
                          moment() <= current
                        }
                        format={dateFormatList}
                        locale={locale}
                        onChange={(date, dateString) =>
                          this.onChangeDatePicker(
                            date,
                            dateString,
                            'createDateCmnd',
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span>Nhập lại Số CCCD/CMND/HC</span>}
                      name="recmnd"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập lại CCCD/CMND/HC',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('cmnd') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'Số CCCD/CMND/HC không trùng khớp',
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        disabled={!!this.props.view}
                        prefix={
                          <IdcardOutlined className="site-form-item-icon" />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Nơi cấp"
                      name="createAddCmnd"
                      rules={[
                        { required: true, message: 'Vui lòng chọn nơi cấp' },
                      ]}
                    >
                      <Select
                        disabled={!!this.props.view}
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        showSearch
                        placeholder="Chọn"
                        options={province.data}
                        notFoundContent="Không tìm thấy kết quả"
                        filterOption={filterSelectOptions}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={8}>
                <Alert
                  className="alert-info-px"
                  message="Lưu ý:"
                  description={
                    <>- Kiểm tra lại thông tin CMND/CCCD/Hộ chiếu.</>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
          </div>
          <div className="section-vnu">
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Quốc tịch</span>}
                  name="nationality"
                  rules={[
                    { required: true, message: 'Vui lòng chọn quốc tịch' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    prefix={<BarsOutlined className="site-form-item-icon" />}
                    showSearch
                    placeholder="Chọn"
                    notFoundContent="Không tìm thấy kết quả"
                    options={countries}
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Dân tộc</span>}
                  name="nation"
                  rules={[{ required: true, message: 'Vui lòng chọn dân tộc' }]}
                >
                  <Select
                    disabled={!!this.props.view}
                    prefix={<BarsOutlined className="site-form-item-icon" />}
                    showSearch
                    placeholder="Chọn"
                    notFoundContent="Không tìm thấy kết quả"
                    options={nations}
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Tôn giáo</span>}
                  name="religion"
                  rules={[
                    { required: true, message: 'Vui lòng chọn tôn giáo' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    prefix={<BarsOutlined className="site-form-item-icon" />}
                    showSearch
                    placeholder="Chọn"
                    options={religions}
                    notFoundContent="Không tìm thấy kết quả"
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Email liên lạc</span>}
                  name="email"
                  rules={[
                    { required: true, message: 'Vui lòng nhập lại Email' },
                    { type: 'email', message: 'Email sai định dạng' },
                  ]}
                >
                  <Input
                    disabled
                    prefix={<MailOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Số điện thoại liên hệ</span>}
                  name="phone"
                  rules={[
                    { required: true, message: 'Vui lòng nhập số điện thoại' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || isVietnamesePhoneNumber(value)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          'Số điện thoại không đúng định dạng',
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    disabled={!!this.props.view}
                    type="number"
                    prefix={<MobileOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="legend">Địa chỉ thường trú (Ghi theo sổ hộ khẩu)</div>
          <div className="section-vnu">
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Tỉnh/TP</span>}
                  name="province_dctt"
                  rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/TP' }]}
                >
                  <Select
                    disabled={!!this.props.view}
                    showSearch
                    placeholder="Chọn"
                    notFoundContent="Không tìm thấy kết quả"
                    autoComplete="province_dctt"
                    options={province.data}
                    onChange={(_) =>
                      this.resetFields(['district_dctt', 'ward_dctt'])
                    }
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Quận/Huyện</span>}
                  name="district_dctt"
                  rules={[
                    { required: true, message: 'Vui lòng chọn Quận/Huyện' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    prefix={<BarsOutlined className="site-form-item-icon" />}
                    showSearch
                    placeholder="Chọn"
                    options={district.dctt}
                    notFoundContent="Không tìm thấy kết quả"
                    onChange={(_) => this.resetField('ward_dctt')}
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Phường/Xã</span>}
                  name="ward_dctt"
                  rules={[
                    { required: true, message: 'Vui lòng chọn Phường/Xã' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    prefix={<BarsOutlined className="site-form-item-icon" />}
                    showSearch
                    placeholder="Chọn"
                    options={ward.dctt}
                    notFoundContent="Không tìm thấy kết quả"
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={<span>Số nhà, tên đường</span>}
                  name="address_dctt"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập số nhà, tên đường',
                    },
                  ]}
                >
                  <Input
                    disabled={!!this.props.view}
                    prefix={
                      <EnvironmentOutlined className="site-form-item-icon" />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="legend">
            Địa chỉ liên lạc{' '}
            <Button
              type="link"
              onClick={() => {
                this.setDcllAsDctt();
              }}
            >
              Giống như địa chỉ thường trú
            </Button>
          </div>
          <div className="section-vnu">
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Tỉnh/TP</span>}
                  name="province_dcll"
                  rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/TP' }]}
                >
                  <Select
                    disabled={!!this.props.view}
                    showSearch
                    placeholder="Chọn"
                    options={province.data}
                    notFoundContent="Không tìm thấy kết quả"
                    autoComplete="province_dcll"
                    onChange={(_) =>
                      this.resetFields(['district_dcll', 'ward_dcll'])
                    }
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Quận/Huyện</span>}
                  name="district_dcll"
                  rules={[
                    { required: true, message: 'Vui lòng chọn Quận/Huyện' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    showSearch
                    placeholder="Chọn"
                    options={district.dcll}
                    notFoundContent="Không tìm thấy kết quả"
                    onChange={(_) => this.resetField('ward_dcll')}
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={<span>Phường/Xã</span>}
                  name="ward_dcll"
                  rules={[
                    { required: true, message: 'Vui lòng chọn Phường/Xã' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    showSearch
                    placeholder="Chọn"
                    options={ward.dcll}
                    notFoundContent="Không tìm thấy kết quả"
                    filterOption={filterSelectOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={<span>Số nhà, tên đường</span>}
                  name="address_dcll"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập số nhà, tên đường',
                    },
                  ]}
                >
                  <Input
                    disabled={!!this.props.view}
                    prefix={
                      <EnvironmentOutlined className="site-form-item-icon" />
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Alert
                  className="alert-info-px"
                  message="Lưu ý:"
                  description={
                    <div>
                      1. Trường hợp nếu đã chọn Phường/Xã ở ô trên thì không cần
                      nhập lại tên Phường/Xã ở ô Số nhà, tên đường.
                      <br />
                      2. Trường hợp nếu tại ô Phường/Xã chọn Khác thì phải nhập
                      tên Phường/Xã ở ô Số nhà, tên đường.
                      <br />
                      3. Địa chỉ này được dùng để gửi giấy báo dự thi, phiếu
                      điểm và liên hệ khi cần thiết.
                    </div>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
          </div>
          <div className="legend">Nơi học Trung học phổ thông</div>
          <div className="section-vnu">
            <Row>
              <Col xs={12}>
                <b>Lớp 10 - Học kỳ I</b>
              </Col>
            </Row>
            <FormHighSchool
              prefix="_10_hk1"
              required={true}
              disabled={!!this.props.view}
              profileObj={profileObj}
              provinces={province.ttcn}
              districts={district._10_hk1}
              schools={school._10_hk1}
              resetFields={(fields, value) => {
                this.resetFields(fields, value);
              }}
            />
          </div>
          <div className="section-vnu">
            <Row>
              <Col xs={24}>
                <b>Lớp 10 - Học kỳ II</b>&nbsp;
                <Button
                  type="link"
                  onClick={() => {
                    this.setThptAs('_10_hk1', '_10_hk2');
                  }}
                >
                  Giống như Học kỳ I, lớp 10
                </Button>
              </Col>
            </Row>
            <FormHighSchool
              prefix="_10_hk2"
              required={true}
              disabled={!!this.props.view}
              profileObj={profileObj}
              provinces={province.ttcn}
              districts={district._10_hk2}
              schools={school._10_hk2}
              resetFields={(fields, value) => {
                this.resetFields(fields, value);
              }}
            />
          </div>
          <div className="section-vnu">
            <Row>
              <Col xs={24}>
                <b>Lớp 11 - Học kỳ I</b>&nbsp;
                <Button
                  type="link"
                  onClick={() => {
                    this.setThptAs('_10_hk2', '_11_hk1');
                  }}
                >
                  Giống như Học kỳ II, lớp 10
                </Button>
              </Col>
            </Row>
            <FormHighSchool
              prefix="_11_hk1"
              disabled={!!this.props.view}
              profileObj={profileObj}
              provinces={province.ttcn}
              districts={district._11_hk1}
              schools={school._11_hk1}
              resetFields={(fields, value) => {
                this.resetFields(fields, value);
              }}
            />
          </div>
          <div className="section-vnu">
            <Row>
              <Col xs={24}>
                <b>Lớp 11 - Học kỳ II</b>&nbsp;
                <Button
                  type="link"
                  onClick={() => {
                    this.setThptAs('_11_hk1', '_11_hk2');
                  }}
                >
                  Giống như Học kỳ I, lớp 11
                </Button>
              </Col>
            </Row>
            <FormHighSchool
              prefix="_11_hk2"
              disabled={!!this.props.view}
              profileObj={profileObj}
              provinces={province.ttcn}
              districts={district._11_hk2}
              schools={school._11_hk2}
              resetFields={(fields, value) => {
                this.resetFields(fields, value);
              }}
            />
          </div>
          <div className="section-vnu">
            <Row>
              <Col xs={12}>
                <b>Lớp 12 - Học kỳ I</b>&nbsp;
                <Button
                  type="link"
                  onClick={() => {
                    this.setThptAs('_11_hk2', '_12_hk1');
                  }}
                >
                  Giống như Học kỳ II, lớp 11
                </Button>
              </Col>
            </Row>
            <FormHighSchool
              prefix="_12_hk1"
              disabled={!!this.props.view}
              profileObj={profileObj}
              provinces={province.ttcn}
              districts={district._12_hk1}
              schools={school._12_hk1}
              resetFields={(fields, value) => {
                this.resetFields(fields, value);
              }}
            />
          </div>
          <div className="section-vnu">
            <Row>
              <Col xs={24}>
                <b>Lớp 12 - Học kỳ II</b>
                &nbsp;
                <Button
                  type="link"
                  onClick={() => {
                    this.setThptAs('_12_hk1', '_12_hk2');
                  }}
                >
                  Giống như Học kỳ I, lớp 12
                </Button>
              </Col>
            </Row>
            <FormHighSchool
              prefix="_12_hk2"
              disabled={!!this.props.view}
              profileObj={profileObj}
              provinces={province.ttcn}
              districts={district._12_hk2}
              schools={school._12_hk2}
              resetFields={(fields, value) => {
                this.resetFields(fields, value);
              }}
            />
          </div>
          <div className="section-vnu section-vnu-le">
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Đối tượng"
                  className="b-r-4 label-auto-height"
                  name="obj_type"
                  rules={[
                    { required: true, message: 'Vui lòng chọn đối tượng' },
                  ]}
                >
                  <Radio.Group disabled={!!this.props.view}>
                    <Radio
                      onClick={() => {
                        this.resetFields([
                          'date_pass',
                          'obj_other',
                          'score_class_10',
                          'score_class_11',
                          'score_class_12',
                        ]);
                        this.setState({ obj_type: 12 });
                      }}
                      value={12}
                    >
                      Học lớp 12
                    </Radio>
                    <Radio
                      onClick={() => {
                        this.resetFields([
                          'date_pass',
                          'obj_other',
                          'score_class_10',
                          'score_class_11',
                          'score_class_12',
                        ]);
                        this.setState({ obj_type: 11 });
                      }}
                      value={11}
                    >
                      Học lớp 11
                    </Radio>
                    <Radio
                      onClick={() => {
                        this.resetFields([
                          'date_pass',
                          'obj_other',
                          'score_class_10',
                          'score_class_11',
                          'score_class_12',
                        ]);
                        this.setState({ obj_type: 10 });
                      }}
                      value={10}
                    >
                      Học lớp 10
                    </Radio>
                    <Radio
                      onClick={() => {
                        this.resetFields([
                          'obj_other',
                          'score_class_10',
                          'score_class_11',
                          'score_class_12',
                        ]);
                        this.setState({ obj_type: 13 });
                      }}
                      value={13}
                    >
                      Thí sinh tự do
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </div>
          {!this.state.obj_type || this.state.obj_type < 11 ? (
            ''
          ) : (
            <div className="section-vnu form_average_score">
              <Row>
                <Col xs={12}>
                  <b>Điểm trung bình chung: </b>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={8}>
                  <Form.Item
                    label={
                      <span>
                        Lớp 10 <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name="score_class_10"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value == '') {
                            return Promise.reject('Vui lòng nhập điểm');
                          }
                          const regex = new RegExp(/^-?[0-9]+(\.[0-9]+)?$/);
                          if (
                            !value ||
                            getFieldValue('score_class_10') === value
                          ) {
                            value = value.replace(',', '.');
                            if (!regex.test(value)) {
                              return Promise.reject('Điểm không hợp lệ');
                            }
                            const match = value.match(/[.,](\d+)$/);
                            if (match && match[1].length > 1) {
                              return Promise.reject(
                                'Chỉ được nhập tối đa 1 số sau dấu phẩy',
                              );
                            }
                            if (value < 0 || value > 10) {
                              return Promise.reject(
                                'Điểm trung bình phải trong khoảng 0 đến 10',
                              );
                            }
                            const regex2 = /^0/;
                            if (regex2.test(value)) {
                              return Promise.reject('Điểm không hợp lệ');
                            }
                            return Promise.resolve();
                          }

                          return Promise.reject('Vui lòng nhập điểm');
                        },
                      }),
                    ]}
                  >
                    <Input disabled={!!this.props.view} prefix={<></>} />
                  </Form.Item>
                </Col>
                {this.state.obj_type < 12 ? (
                  ''
                ) : (
                  <Col xs={24} md={8}>
                    <Form.Item
                      label={
                        <span>
                          Lớp 11 <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="score_class_11"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value == '') {
                              return Promise.reject('Vui lòng nhập điểm');
                            }
                            const regex = new RegExp(/^-?[0-9]+(\.[0-9]+)?$/);
                            if (
                              !value ||
                              getFieldValue('score_class_11') === value
                            ) {
                              value = value.replace(',', '.');
                              if (!regex.test(value)) {
                                return Promise.reject('Điểm không hợp lệ');
                              }
                              const match = value.match(/[.,](\d+)$/);
                              if (match && match[1].length > 1) {
                                return Promise.reject(
                                  'Chỉ được nhập tối đa 1 số sau dấu phẩy',
                                );
                              }
                              if (value < 0 || value > 10) {
                                return Promise.reject(
                                  'Điểm trung bình phải trong khoảng 0 đến 10',
                                );
                              }

                              const regex2 = /^0/;
                              if (regex2.test(value)) {
                                console.log('Ký tự đầu là số 0.');
                                return Promise.reject('Điểm không hợp lệ');
                              }

                              return Promise.resolve();
                            }
                            return Promise.reject('Vui lòng nhập điểm');
                          },
                        }),
                      ]}
                    >
                      <Input disabled={!!this.props.view} prefix={<></>} />
                    </Form.Item>
                  </Col>
                )}
                {this.state.obj_type < 13 ? (
                  ''
                ) : (
                  <Col xs={24} md={8}>
                    <Form.Item
                      label={
                        <span>
                          Lớp 12 <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name="score_class_12"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value == '') {
                              return Promise.reject('Vui lòng nhập điểm');
                            }
                            const regex = new RegExp(/^-?[0-9]+(\.[0-9]+)?$/);
                            if (
                              !value ||
                              getFieldValue('score_class_12') === value
                            ) {
                              value = value.replace(',', '.');
                              if (!regex.test(value)) {
                                return Promise.reject('Điểm không hợp lệ');
                              }
                              const match = value.match(/[.,](\d+)$/);
                              if (match && match[1].length > 1) {
                                return Promise.reject(
                                  'Chỉ được nhập tối đa 1 số sau dấu phẩy',
                                );
                              }
                              if (value < 0 || value > 10) {
                                return Promise.reject(
                                  'Điểm trung bình phải trong khoảng 0 đến 10',
                                );
                              }
                              const regex2 = /^0/;
                              if (regex2.test(value)) {
                                console.log('Ký tự đầu là số 0.');
                                return Promise.reject('Điểm không hợp lệ');
                              }
                              return Promise.resolve();
                            }
                            return Promise.reject('Vui lòng nhập điểm');
                          },
                        }),
                      ]}
                    >
                      <Input disabled={!!this.props.view} prefix={<></>} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <p>
                Lưu ý:
                <br />
                - Điểm trung bình chung (TBC) từng năm học sẽ được tính: (TBC
                HK1 + TBC HK2)/2
                <br />- Điểm TBC HK = Điểm trung bình tổng các môn học được đánh
                giá bằng điểm số trong học kỳ
              </p>
            </div>
          )}
          {/* <div className="legend">Thông tin ưu tiên</div>
          <div className="section-vnu">
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <SelectPriorityArea name="priority_area" />
              </Col>
              <Col xs={24} md={8}>
                <SelectPriorityObject name="priority_object" />
              </Col>
            </Row>
          </div> 
          <div className="section-vnu section-vnu-le">
            <Row>
              <Form.Item noStyle name="hktt_kv1_18m" valuePropName="checked">
                <Checkbox>
                  Hộ khẩu thường trú trên 18 tháng tại <b>Khu vực 1</b>.
                </Checkbox>
              </Form.Item>
            </Row>
            <Row>
              <Form.Item noStyle name="hktt_dbkk_18m" valuePropName="checked">
                <Checkbox>
                  Hộ khẩu thường trú trên 18 tháng tại{' '}
                  <b>xã đặc biệt khó khăn</b>.
                </Checkbox>
              </Form.Item>
            </Row>
          </div>*/}

          {!this.props.view && (
            <Form.Item>
              <div className="flex flex-end pb-4 step-action-vnu">
                <Link to={this.getReturnUrl()}>
                  <Button type="default" size="large" className="b-r-8">
                    Hủy
                  </Button>
                </Link>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={this.state.loading}
                  className="ml-2 b-r-8"
                >
                  {this.state.loading ? (
                    <div>
                      <img
                        alt=""
                        className="icon-loading"
                        src={config.getLoadingDefault()}
                      />
                      Đang gửi
                    </div>
                  ) : (
                    <span>Lưu lại</span>
                  )}
                </Button>
              </div>
            </Form.Item>
          )}
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  isGuest: Object.keys(state.user).length === 0,
});

export default connect(mapStateToProps)(HustForm);
