import config from 'app/config';
import QS from 'app/utils/query-string';
import axios from 'axios';
export function getCities(type = '') {
  let apiUrl = `${process.env.REACT_APP_API_E_DOMAIN}assets/get-cities`;
  const method = 'get';
  if (type == 1) {
    apiUrl = `${config.getApiDomain()}/address/province`;
  }

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function saveRegisterCer(data) {
  let apiUrl = `${config.getApiDomain()}/exam-plan/update-register-cer`;

  return axios({
    method: 'post',
    url: apiUrl,
    data: data,
  }).then((response) => response);
}
export function saveCashbackInfo(data) {
  let apiUrl = `${config.getApiDomain()}/exam-plan/update-cashback-info`;

  return axios({
    method: 'post',
    url: apiUrl,
    data: QS.stringify(data),
  }).then((response) => response);
}

export function checkCode(data) {
  let apiUrl = `${config.getApiDomain()}/test-code/check-code`;

  return axios({
    method: 'post',
    url: apiUrl,
    data: QS.stringify(data),
  }).then((response) => response.data);
}

export function getResultExamTest(id) {
  let apiUrl = `${config.getApiDomain()}/test-code/get-results?exam_id=` + id;

  return axios({
    method: 'get',
    url: apiUrl,
  }).then((response) => response.data);
}
