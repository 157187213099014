import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as queryString from 'query-string';
import { Form, Input, Button, message } from 'antd';
import { Container, Row, Col } from 'reactstrap';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Auth from 'app/auth';
import logo from 'themes/default/logo-full.svg';
import {
  getTokenByCode,
  login,
  registerOther,
  resetPasswordWithEmail,
} from '../../service';
import {
  clearLocalStorage,
  getErrorMessages,
  isCustomDomain,
} from 'app/utils/functions';
import store from 'app/store';
import ic_fb from 'assets/img/theme/facebook.png';
import config from 'app/config';
import theme from 'themes';
import PATHS from 'themes/paths';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      loginObj: {
        username: '',
        password: '',
      },
      loginError: {
        username: false,
        password: false,
      },
      access_token: '',
      refresh_token: '',
      email: '',
      loading: false,
      returnUrl: '',
    };
    this.timeOut = process.env.REACT_APP_SET_TIMEOUT_LOGIN ?? 3000;
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const returnUrl = params.returnUrl || this.props.location.returnUrl || '';

    this.setState({ returnUrl });

    if (params.code) {
      this.retrieveTokens(params.code, returnUrl);
    }
  }

  retrieveTokens = async (code, returnUrl) => {
    const url = returnUrl
      ? `${process.env.REACT_APP_DOMAIN}${PATHS.login}?returnUrl=${returnUrl}`
      : `${process.env.REACT_APP_DOMAIN}${PATHS.login}`;
    const response = await getTokenByCode(code, url);
    if (response.status == 200) {
      const create_acc = await registerOther({
        token: response.data.access_token,
      });

      if (create_acc.data.code == 200) {
        localStorage.setItem('authen_access_token', response.data.access_token);
        localStorage.setItem(
          'authen_refresh_token',
          response.data.refresh_token,
        );
        store.dispatch({
          type: 'UPDATE_USER_TOKEN',
          userToken: response.data.access_token,
        });
        store.dispatch({
          type: 'UPDATE_USER',
          user: create_acc.data.data.user,
        });
        localStorage.setItem('refresh_event', Math.random());
        if (returnUrl) {
          window.location.href = returnUrl;
        } else {
          window.location.href = theme.config.auth.loginRedirect;
        }
      }
    }
  };

  changeVal = (changedValues, allValues) => {
    this.setState({
      loginObj: allValues,
    });
  };

  onFormSubmit = async () => {
    this.setState({ loading: true });
    clearLocalStorage();
    const { loginObj } = this.state;
    const loginError = {
      username: false,
      password: false,
    };
    if (!loginObj.username) {
      loginError.username = true;
      this.setState({ loginError, loading: false });

      return message.error('Bạn cần nhập tên đăng nhập');
    }
    if (!loginObj.password) {
      loginError.password = true;
      this.setState({ loginError, loading: false });

      return message.error('Bạn cần nhập mật khẩu');
    }

    const response = await login(loginObj.username, loginObj.password);

    if (response.status == 200) {
      await Auth.verifyUser(
        response.data.access_token,
        response.data.refresh_token,
      )
        .then((res) => {
          if (res.success) {
            localStorage.setItem('refresh_event', Math.random());
            if (this.state.returnUrl) {
              window.location.href = this.state.returnUrl;
            } else {
              window.location.href = theme.config.auth.loginRedirect;
            }
          } else {
            if (typeof res.data.message != 'undefined') {
              message.error(res.data.message);
            } else {
              message.error('Có lỗi xảy ra trong quá trình thực hiện');
            }
            setTimeout(() => {
              this.setState({ loading: false });
            }, this.timeOut);
          }
        })
        .catch((error) => {
          console.log('Không thể kết nối với máy chủ: ', error);
          setTimeout(() => {
            this.setState({ loading: false });
          }, this.timeOut);
        });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, this.timeOut);
      if (
        response &&
        response.response &&
        response.response.data &&
        response.response.data.error &&
        response.response.data.error == 'invalid_grant'
      ) {
        message.error('Tài khoản hoặc mật khẩu của bạn không đúng');
      } else {
        message.error('Không kết nối được SSO');
      }
    }
  };

  forgetPassword = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onFormChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  resetPassword = async () => {
    if (!this.state.email) {
      return message.error('Bạn chưa nhập email');
    }
    const response = await resetPasswordWithEmail(this.state.email);
    if (response.data.code == 200) {
      message.success(response.data.message);
      this.setState({
        modal: false,
      });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  render() {
    const { loginObj } = this.state;
    const returnUrl =
      this.props.location && this.props.location.returnUrl
        ? this.props.location.returnUrl
        : '';

    let logoSrc = logo;
    let customDomain = isCustomDomain();
    if (customDomain) {
      logoSrc = require('./../../../../assets/img/custom-logo/' +
        customDomain +
        '.png').default;
    }

    return (
      <div className="page-login app flex-row">
        <>
          <Container className="mt-6">
            <Row>
              <Col xs="12" className="">
                <div className="text-center">
                  <img className="logo-kt mb-4 mt-4" alt="..." src={logoSrc} />
                </div>
                <div className="auth-form ">
                  <div className="auth-form__inner-wrap">
                    <div className="mb-5">
                      <h1 className="auth-form__header-welcome">Đăng nhập</h1>
                      {!!customDomain || (
                        <p className="text-center">
                          Bạn chưa có tài khoản?&nbsp;
                          <Link
                            to={{
                              pathname: PATHS.register,
                              returnUrl,
                            }}
                            className="link"
                          >
                            Đăng ký ngay
                          </Link>
                        </p>
                      )}
                    </div>
                    {!!customDomain || (
                      <>
                        <div className="btn-gr-mxh mb-4">
                          <a
                            href={config.getUrlLoginFB(returnUrl)}
                            color=""
                            className="btn btn-pill social-auth social-auth--fb"
                          >
                            <span className="icon js-facebook-inner">
                              <img src={ic_fb} alt="Facebook" />
                            </span>
                            <span className="font-face-lt">Facebook</span>
                          </a>
                          <a
                            href={config.getUrlLoginGG(returnUrl)}
                            color=""
                            className="btn btn-pill social-auth social-auth-gg"
                          >
                            <span className="icon js-google-inner">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="24"
                                  height="24"
                                  rx="5"
                                  fill="#fff"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M20.64 12.204c0-.638-.057-1.252-.164-1.84H12v3.48h4.844a4.14 4.14 0 01-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z"
                                  fill="#4285F4"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12 21c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H3.957v2.332A8.997 8.997 0 0012 21z"
                                  fill="#34A853"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6.964 13.71A5.41 5.41 0 016.682 12c0-.593.102-1.17.282-1.71V7.958H3.957A8.997 8.997 0 003 12c0 1.452.348 2.827.957 4.042l3.007-2.332z"
                                  fill="#FBBC05"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12 6.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C16.463 3.891 14.426 3 12 3a8.997 8.997 0 00-8.043 4.958l3.007 2.332C7.672 8.163 9.656 6.58 12 6.58z"
                                  fill="#EA4335"
                                />
                              </svg>
                            </span>
                            <span className="font-face-lt">Google</span>
                          </a>
                        </div>
                        <div className="or-line">
                          <p className="or__line">
                            <span className="or__text">Hoặc</span>
                          </p>
                        </div>
                      </>
                    )}
                    <Form
                      layout="vertical"
                      name="basic"
                      className="profile-form login-form"
                      initialValues={loginObj}
                      onValuesChange={this.changeVal}
                      // key={this.state.resetForm}
                    >
                      <Form.Item
                        label={
                          <span>
                            Email / Tên đăng nhập{' '}
                            <span className="text-danger">*</span>
                          </span>
                        }
                        name="username"
                      >
                        <Input
                          className={
                            this.state.loginError.username
                              ? 'is-invalid-date'
                              : ''
                          }
                          placeholder=""
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          // suffix={
                          //   <Tooltip title="Extra information">
                          //     <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                          //   </Tooltip>
                          // }
                        />
                      </Form.Item>
                      {/* <span onClick={this.forgetPassword} className="hidden cursor text-right">Quên mật khẩu?</span> */}
                      <Form.Item
                        label={
                          <span>
                            Mật khẩu <span className="text-danger">*</span>
                          </span>
                        }
                        name="password"
                      >
                        <Input.Password
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          className={
                            this.state.loginError.password
                              ? 'is-invalid-date'
                              : ''
                          }
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          className="b-r-8 btn-submit"
                          block
                          htmlType="submit"
                          onClick={this.onFormSubmit}
                          disabled={this.state.loading ? true : false}
                        >
                          {this.state.loading ? (
                            <span className="font-face-lt">
                              <img
                                alt=""
                                className="icon-loading"
                                src={config.getLoadingDefault()}
                              />
                              &nbsp;
                              <span>Đang gửi</span>
                            </span>
                          ) : (
                            <span className="font-face-lt">
                              <span>Đăng nhập</span>
                            </span>
                          )}
                        </Button>
                      </Form.Item>
                    </Form>
                    {/* <Form className="form-login" action="" method="post" onSubmit={(event) => { event.preventDefault(); this.onFormSubmit() }}>
                        <FormGroup>
                          <Label htmlFor="username">Tên đăng nhập <span className="text-danger">*</span></Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                            </InputGroupAddon>
                            <Input autoComplete="username" type="text" name="username" id="name" placeholder="" required  value={this.state.loginObj.username || ''} onChange={this.onFormChange} invalid={this.state.loginError.username ? true : false}/>
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                        <Label htmlFor="password" className="ant-btn-block">Mật khẩu <span className="text-danger">*</span> <span onClick={this.forgetPassword} className="hidden cursor text-right">Quên mật khẩu?</span></Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText><i className="fa fa-lock"></i></InputGroupText>
                            </InputGroupAddon>
                            <Input autoComplete="password" type="password" name="password" id="password" placeholder="" required  value={this.state.loginObj.password || ''} onChange={this.onFormChange} invalid={this.state.loginError.password ? true : false}/>
                          </InputGroup>
                        </FormGroup>

                        <div className="mb-2">
                          <Button type="primary" block className="btn-form" onClick={this.onFormSubmit}>
                            {this.state.loading
                            ?
                            <span className="font-face-lt">
                              <img className="icon-loading" src={config.getLoadingDefault()} /> <span>Đang gửi</span>
                            </span>
                            :
                            <span className="font-face-lt">
                              <span>Đăng nhập</span>
                            </span>
                            }
                          </Button>
                        </div>

                        <button type="submit" size="sm" color="success" hidden>Submit</button>
                      </Form> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>

        {/* <Modal
          title={<div className="text-center mt-4 my-2"><h2>Lấy lại mật khẩu</h2><p>Nhập email dùng để đăng ký tài khoản của bạn.<br/>
          Chúng tôi sẽ gửi bạn một đường link để reset lại mật khẩu vào email này</p></div>}
          visible={this.state.modal}
          onOk={this.forgetPassword}
          onCancel={this.forgetPassword}
          footer={null}
          keyboard={true}
          maskClosable={true}
          closable={true}
          centered={true}
          className="modal-setting-info"
          closeIcon={<i className="fa fa-times-circle" aria-hidden="true"></i>}
        >
          <FormGroup>
            <Label htmlFor="email">Địa chỉ email</Label>
            <Input type="text" name="email" placeholder=""  value={this.state.email || ''} onChange={this.onFormChangeEmail}/>
          </FormGroup>
          <Button className="btn-form" type="primary" block onClick={this.resetPassword}>Gửi tôi mật khẩu mới</Button>
        </Modal> */}
      </div>
    );
  }
}

export default Login;
